@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

  #resetPw-title {
    margin-top: 10rem;
    text-align: center;
  }

  #resetPwBtn {
    width: 15rem;
    margin-top: 3rem;
    box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.4);
    margin-bottom: 1rem;
  }

  .pin-input-div {
    display: flex;
    justify-content: space-between;
    .pin-input {
      margin-right: .5rem;
      height: 3rem;
      color: $primaryTextColor;
      font-family: $primaryFont;
      text-align: center;
      font-size: 2rem;
    }
  }

  #pw-req {
    font-size: 1.25rem;
    color: $primaryTextColor;
    font-family: $primaryFont;
  }

  .MuiSvgIcon-root {
    align-self: center;
    margin: 0 .5rem
  }
  
  .resetPwForm
  {
    border: 0.1rem solid #333333;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.8);
  }

  .pw-criteria 
  {
    background-color: $formInputBackgroundColor;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
    .pwCritItems
    {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      svg {
        font-size: 1.6rem;
        margin-right: 1rem;
      }
    
      .met {
        color: green;
      }
    
      .notMet {
        color: red;
      }

      p {
        color: $primaryTextColor;
        font-family: $primaryFont;
        font-size: 1.5rem;
      }
    }
  }
  
}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) {

  #resetPwBtn {
    width: 18rem;
  }

  #pw-box
  {
    margin-left: 0;
    margin: 0 auto;
  }

  .resetPwForm
  {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pwInputs
    {
      width: 80%;
      label, input
      {
        width: 100%;
      }
    }
  }

  #pw-criteria 
  {
    width: 80%;
  }

  .pin-label {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .pin-input-div {
    margin: 0 auto;
    width: 80%;
    .pin-input {
      width: 5rem;
      height: 5rem;
      color: $primaryTextColor;
      font-family: $primaryFont;
      text-align: center;
      font-size: 3rem;
      margin: 0 0 2rem 0;
    }
  }

}

// Large devices (desktops, 992px and below)
@media (min-width: 992px) {

  #resetPw-title {
    margin: 10rem auto 4rem;
  }

  .resetPwForm
  {
    width: 65rem;
    margin: 0 auto;
    padding-top: 2rem;
  }
  

}

// Extra large devices (large desktops, 1200px and below)
@media (min-width: 1200px) {}