@import '../../index.scss';
@import '../components.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

    #add-module-div {
        width: 90%;
        margin: 1rem auto;

        h1 {
            font-size: 2rem;
            color: $primaryTextColor;
            font-family: $primaryFont;
            margin-bottom: 2rem;
        }

        form {
            .add-module-row {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .add-module-form-input {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    label {
                        margin: 0 0 0.5rem 0;
                        color: $primaryTextColor;
                        font-family: $primaryFont;
                        font-size: 1.25rem;
                    }

                    input {
                        margin: 0 0 1rem 0;
                        color: $primaryTextColor;
                        font-family: $primaryFont;
                        font-size: 1.25rem;
                    }
                    
                }

                .add-module-course-dropdown {
                    display: flex;
                    width: 100%;
                    flex-direction: column;

                    label {
                        font-size: 1.25rem;
                        margin: 0;
                    }

                    select {
                        margin-top: 0.5rem;
                        margin-bottom: 1rem;
                        height: 2.8rem;
                        font-size: 1.5rem;
                        background-color: $formInputBackgroundColor;
                        color: $primaryTextColor;
                        font-family: $primaryFont;
                        border: none;
                        border-radius: .5rem;
                        padding: 0.5rem;
                    }
                }

                .add-tag-dropdown {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 2rem;
                    #tag-select-header {
                      font-size: 1rem;
                      color: $primaryTextColor;
                      font-family: $primaryFont;
                      margin-bottom: 0.5rem;
                    }
                    select {
                      height: 10rem;
                      width: 20.3rem;
                      background-color: $formInputBackgroundColor;
                      padding: 1rem;
                      option {
                        font-size: 1.25rem;
                        color: $primaryTextColor;
                        font-family: $primaryFont;
                      }
                      option:hover {
                        background-color: $primaryHoverColor;
                        cursor: pointer;
                      }
                    }
                  }
            }

            .dropdown-div {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .edit-module-hardcode {
                    width: 100%;
                }

                #module-cust-name,
                #module-cust-useCase {
                    color: $primaryTextColor;
                    font-family: $primaryFont;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    font-size: 1.25rem;
                    margin-left: 0.5rem;
                }

                .add-module-dropdown {
                    display: flex;
                    width: 100%;
                    flex-direction: column;

                    label {
                        font-size: 1.25rem;
                        margin: 0;
                    }

                    select {
                        margin-top: 0.5rem;
                        margin-bottom: 1rem;
                        height: 2.8rem;
                        font-size: 1.5rem;
                        background-color: $formInputBackgroundColor;
                        color: $primaryTextColor;
                        font-family: $primaryFont;
                        border: none;
                        border-radius: .5rem;
                        padding: 0.5rem;
                    }
                }

                .add-usecase-dropdown {
                    display: flex;
                    width: 100%;
                    flex-direction: column;

                    label {
                        font-size: 1.25rem;
                        margin: 0;
                    }

                    select {
                        margin-top: 0.5rem;
                        margin-bottom: 1rem;
                        height: 2.8rem;
                        font-size: 1.5rem;
                        background-color: $formInputBackgroundColor;
                        color: $primaryTextColor;
                        font-family: $primaryFont;
                        border: none;
                        border-radius: .5rem;
                        padding: 0.5rem;
                    }
                }

                .add-module-cust {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    label {
                        margin: 0;
                    }
                }
            }

            .add-module-form-textarea {
                display: flex;
                width: 100%;
                flex-direction: column;

                label {
                    font-size: 1.25rem;
                    margin: 0;
                }

                textarea {
                    margin-top: 0.5rem;
                    margin-bottom: 1rem;
                    height: 5rem;
                    font-size: 1.25rem;
                    background-color: $formInputBackgroundColor;
                    color: $primaryTextColor;
                    font-family: $primaryFont;
                    border: none;
                    border-radius: .5rem;
                    padding: 0.5rem;
                }
            }

            #add-module-btns {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-top: 2rem;

                button {
                    margin: 0 0.5rem;
                    background-color: $buttonColor;
                    width: 8rem;
                    border-radius: 0.5rem;

                    span {
                        color: $primaryTextColor;
                        font-family: $primaryFont;
                        font-size: 1.25rem;
                    }
                }
            }
        }

        .message {
            text-align: center;
        }
    }

}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) {

    #add-module-div {
        margin-top: 6rem;

        h1 {
            font-size: 3rem;
            margin-bottom: 3rem;
        }

        form {
            .add-module-row {
                justify-content: space-between;

                .add-module-form-input {
                    width: 45%;

                    label {
                        font-size: 1.5rem;
                        width: 45%;
                    }

                    input {
                        font-size: 1.5rem;
                    }
                }

                .add-module-course-dropdown {
                    width: 45%;

                    label {
                        font-size: 1.5rem;
                        width: 45%;
                    }

                    select {
                        height: 3.1rem;
                        font-size: 1.5rem;
                    }
                }

                .add-class-dropdown, .add-tag-dropdown {
                    label {
                      font-size: 1.75rem;
                    }
                    #class-select-header, #tag-select-header {
                      font-size: 1rem;
                    }
                    select {
                      height: 15rem;
                      option {
                        font-size: 1.5rem;
                      }
                    }
                  }
            }

            .dropdown-div {
                justify-content: space-between;

                .edit-module-hardcode {
                    width: 45%;
                }

                #module-cust-name,
                #module-cust-useCase {
                    font-size: 1.5rem;
                }

                .add-module-dropdown {
                    width: 45%;

                    label {
                        font-size: 1.5rem;
                    }

                    select {
                        height: 3.1rem;
                        font-size: 1.5rem;
                    }
                }

                .add-module-cust {
                    width: 45%;
                }
            }

            .add-module-form-textarea {
                label {
                    font-size: 1.5rem;
                }

                textarea {
                    height: 10rem;
                    font-size: 1.5rem;
                }
            }

            #add-module-btns {
                button {
                    margin: 0 1rem;
                    width: 10rem;

                    span {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) {

    #add-module-div {
        margin-top: 8rem;

        form {
            .add-module-row {
                .add-module-form-input {
                    input {
                        width: 100%;
                    }
                }
            }
        }
    }

}

// Large devices (desktops, 992px and above)
@media (min-width: 992px) {

    #add-module-div {
        margin-top: 10rem;
        width: 79rem;


        .add-module-cust {
            width: 45%;

            label {
                width: 45%;
            }
        }
    }

}

// Extra large devices (large desktops, 1200px and above)
@media (min-width: 1200px) {}