@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 

    #users-div {
      margin-left: 1rem;
      margin-top: 1rem;
      margin-right: 1rem;
      color: $primaryTextColor;
      font-family: $primaryFont;
      div h1 {
        font-size: 1.5rem;
      }
      #users-top-header {
        display: flex;
        align-items: center;
        #user-action {
          display: flex;
          #search-text {
            background-color: $formInputBackgroundColor;
            color: $primaryTextColor;
            font-family: $primaryFont;
            padding: 0.5rem;
            font-size: 1rem;
            margin-right: 1rem;
          }
          #add-user-btn {
            color: $primaryTextColor;
            background-color: $buttonColor;
            padding: .2rem 0;
            width: 7rem;
            border-radius: 0.5rem;
            span {
              font-size: 1rem;
            }
          }
        }
      }
      #users-titles {
        display: flex;
        width: 100%;
        justify-content: space-between;
        background-color: $tertiaryBackgroundColor;
        margin-top: 1rem;
        padding: 0.5rem;
        #user-role-header {
          display: none;
        }
       
        #secondary-user-headers {
          align-items: center;
          #user-metrics-header {
            display: none;
          }
          #user-status-header {
            display: none;
          }
          #user-more-header {
            margin-right: 0.5rem;
          }
        }
      }
      #user-info-div {
        display: flex;
        justify-content: space-between;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        #user-name {
          margin-top: 1rem;
          margin-bottom: 0.5rem;
          #user-email {
            font-style: italic;
          }
        }
        #secondary-user-data {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        #user-role {
          display: none;
        }
        #user-metrics {
          display: none;
        }
        #user-status {
          display: none;
          justify-content: space-between;
          #active-cir, #invited-cir {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
          }
          
          #active-cir {
            background-color: #34A400;
            margin-right: 0.5rem;
          }
      
          #invited-cir {
            background-color: #FFF500;
            margin-right: 0.3rem;
          }
        }
        #user-more {
          .edit-btn {
            background-color: $buttonColor;
            width: 3rem;
            padding: 0.1rem 0.5rem;
            border-radius: 0.5rem;
            span {
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-size: 1rem;
            }
          }
        }
      }
      .select-div:hover {
        background-color: $primaryHoverColor;
        cursor: pointer;
      }
      .user-line {
        border: 1px solid #5A6770;
      }

      #show-more-div {
        display: flex;
        justify-content: center;
        margin: 1rem 0;
        #show-more-btn, #show-less-btn  {
          background-color: $buttonColor;
          width: 7rem;
          padding: 0.5rem 0.5rem;
          border-radius: 0.5rem;
          span {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1rem;
          }
        }
        #show-more-btn {
          margin-right: 0.5rem;
        }
        #show-less-btn {
          margin-left: 0.5rem;
        }
      }
    }  

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    #users-div {
      div h1 {
        font-size: 3rem;
      }
      #users-top-header {
        #user-action {
          #search-text {
            font-size: 1.25rem;
            width: 21rem;
          }
          #add-user-btn {
            height: 3rem;
            width: 10rem;
            span {
              font-size: 1.25rem;
            }
          }
        }
      }
      #users-titles {
        padding: 1rem;
        h2 {
          font-size: 1.25rem;
        }
        #user-name-header {
          width: 50%;
        }
        #secondary-user-headers {
          width: 50%;
          display: flex;
          justify-content: space-around;
          #user-role-header {
            display: block;
            width: 33.333%;
            text-align: center;
          }
          #user-metrics-header {
            display: block;
            width: 33.333%;
            text-align: center;
          }
          #user-status-header {
            display: block;
            margin-right: 0rem;
            width: 33.333%;
            text-align: center;
          }
          #user-more-header {
            margin: 0;
            width: 33.333%;
            text-align: center;
          }
        }
      }
      #user-info-div {
        margin-left: 1rem;
        margin-right: 1rem;
        p {
          font-size: 1.25rem;
        }
        #user-name {
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        }
        #secondary-user-data {
          display: flex;
          align-items: center;
          width: 50%;
          #user-role {
            width: 25%;
            display: block;
            text-align: center;
          }
          #user-metrics {
            width: 25%;
            display: flex;
            justify-content: center;
            align-content: center;
            .user-hasMetrics-icon, .user-noMetrics-icon {
              font-size: 1.5rem;
              margin-right: 0.25rem;
              border-radius: 50%;
              height: 1.5rem;
              width: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .user-hasMetrics-icon{
              background-color: green;
            }
            .user-noMetrics-icon {
              background-color: $unpublishedBackgroundColor;
            }
          }
          #user-status {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25%;
            text-align: center;
            #active-cir, #invited-cir {
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
            }
            
            #active-cir {
              background-color: #34A400;
              margin-right: 0.5rem;
            }
        
            #invited-cir {
              background-color: #FFF500;
              margin-right: 0.3rem;
            }
          }
          #user-more {
            width: 25%;
            text-align: center;
            .edit-btn {
              width: 5rem;
              span {
                font-size: 1.25rem;
              }
            }
          }
        }
      }
      
      .user-line {
        border: 1px solid #5A6770;
      }

      #show-more-div {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        #show-more-btn, #show-less-btn  {
          width: 9rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }  

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
  
   }