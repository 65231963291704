@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {
    .manual-download-page {
        width: 100%;
        height: 100vh;
        margin-left: 9rem;
        .build-download {
            margin-top: 2rem;
            margin-left: 2rem;
            display: flex;
            flex-direction: column;
            h1 {
                font-size: 2rem;
                font-family: $primaryFont;
                color: $primaryTextColor;
                margin-bottom: 1rem;
            }
            a {
                width: max-content;
                font-size: 1.5rem;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                color: rgb(133,174,239)
            }
            #login-page-btn {
                background: $buttonColor;
                border-radius: .5rem;
                width: 8rem;
                padding: .25rem;
                margin-top: 1rem;
                span {
                    color: $primaryTextColor;
                    font-family: $primaryFont;
                    font-size: 1rem;
                }
            }
        }
    }
}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) {
    .manual-download-page {
        .build-download {
            h1 {
                font-size: 2.5rem;
            }
            a {
                font-size: 1.75rem;
            }
            #login-page-btn {
                width: 13rem;
                padding: .5rem;
    
                span {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) { 
    .manual-download-page {
        .build-download {
            h1 {
                font-size: 3rem;
            }
            a {
                font-size: 2rem;
            }
        }
    }
 }

// Large devices (desktops, 992px and below)
@media (min-width: 992px) { 

 }

// Extra large devices (large desktops, 1200px and below)
@media (min-width: 1200px) { 

 }