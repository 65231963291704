@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

    #student-quiz-results-display {
        width: 90%;
        margin: 0 auto;
        margin-top: 5%;

        h1,
        h2,
        h3,
        h4,
        p {
            font-family: $primaryFont;
            color: $primaryTextColor;
        }

        h1 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        h2 {
            font-size: 1.75rem;
            margin-bottom: 1rem;
        }
        .student-quiz-overall-summary {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            .quiz-overall-score-perc {
                margin-right: 0.5rem;
            }
            p {
                font-size: 1.25rem;
            }
            .student-confidence-status {
                display: flex;
                justify-content: center;
                align-items: center;
                .high-confidence-cir,
                .medium-confidence-cir,
                .low-confidence-cir {
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                }
                .high-confidence-cir {
                    background-color: #34A400;
                    margin-right: 0.5rem;
                }
                .medium-confidence-cir {
                    background-color: #FFF500;
                    margin-right: 0.5rem;
                }
                .low-confidence-cir {
                    background-color: #FF0000;
                    margin-right: 0.5rem;
                }
            }
        }

        .student-quiz-attempt-summary {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            .quiz-attempt-score-perc {
                font-style: italic;
                margin-right: 0.5rem;
            }
            p {
                font-size: 1.00rem;
            }
            .student-confidence-status {
                display: flex;
                justify-content: center;
                align-items: center;
                .high-confidence-cir,
                .medium-confidence-cir,
                .low-confidence-cir {
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                }
                .high-confidence-cir {
                    background-color: #34A400;
                    margin-right: 0.5rem;
                }
                .medium-confidence-cir {
                    background-color: #FFF500;
                    margin-right: 0.5rem;
                }
                .low-confidence-cir {
                    background-color: #FF0000;
                    margin-right: 0.5rem;
                }
            }
        }

        #elapsed-time {
            margin-bottom: 1rem;
            font-size: 1.25rem;
        }

        #download-results-btn {
            background-color: $buttonColor;
            border-radius: 0.5rem;
            margin-bottom: 1rem;
            padding: 1rem;
            a {
                text-decoration: none;
                font-size: 1rem;
                color: $primaryTextColor;
                font-family: $primaryFont
            }
        }

        #download-results-btn:hover {
            background-color: $primaryHoverColor;
            cursor: pointer;
        }

        #student-quiz-results {
            background-color: $secondaryBackgroundColor;
            margin-bottom: 2rem;
            .quiz-results-header {
                h3 {
                    text-align: center;
                    padding: 1rem 0;
                    font-size: 1.25rem;
                }
                h4 {
                    padding: 1rem;
                    font-size: 1.25rem;
                }
            }

            .question-list-div {
                background-color: $primarybackgroundColor;
                border-bottom: 1rem solid $secondaryBackgroundColor;
                border-left: 1rem solid $secondaryBackgroundColor;
                border-right: 1rem solid $secondaryBackgroundColor;
                padding: 1rem 0.5rem;

                .question-card {
                    margin-bottom: 2rem;
                    .question-header {
                        display: flex;
                        justify-content: space-between;
                        h4 {
                            font-size: 1.25rem;
                            margin-bottom: 0.5rem;
                        }
                        .correct-mark {
                            color: #34A400;
                            font-size: 1.5rem;
                        }
                        .incorrect-mark {
                            color: #FF0000;
                            font-size: 1.5rem;
                        }
                    }
                    .question {
                        font-size: 1rem;
                        margin-bottom: 0.5rem;
                    }
                    .question-details {
                        .question-choices {
                            margin-bottom: 1rem;
                            .choice {
                                display: flex;
                                align-items: center;
                                p {
                                    font-size: 1rem;
                                }
                                .banner-div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    p {
                                        font-size: 1rem;
                                    }
                                    .correct-banner, .incorrect-banner, .empty-banner {
                                        height: 1.5rem;
                                        width: 6rem;
                                        text-align: center;
                                        border-radius: 0.5rem;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                    .correct-banner {
                                        background-color: #34A400;
                                    }
                                    .incorrect-banner {
                                        background-color: #FF0000;
                                    }
                                    .arrow-right-correct, .arrow-right-incorrect, .arrow-right-empty {
                                        margin-right: 0.5rem;
                                        height: 1rem;
                                        width: 1rem;
                                        transform: matrix(0.82, -0.41, 0.69, .4, -6, .55)
                                    }
                                    .arrow-right-correct {
                                        background: #34A400;
                                    }
                                    .arrow-right-incorrect {
                                        background: #FF0000;
                                    }
                                }
                                .radio-btn {
                                    margin-right: 1rem;
                                }
                                .question-confidence {
                                    margin-left: 1rem;
                                }
                                .low-conf {
                                    color: red;
                                }
                                .med-conf {
                                    color: yellow;
                                }
                                .high-conf {
                                    color: green;
                                }
                            }
                        }
                    }
                } 
            }
        }
        .back-btn-div {
            text-align: center;

            .back-btn {
                background-color: $buttonColor;
                width: 5rem;
                padding: 0.1rem 0.5rem;
                border-radius: 0.5rem;
                margin-top: 1rem;
                margin-bottom: 4rem;
                span {
                    color: $primaryTextColor;
                    font-family: $primaryFont;
                    font-size: 1rem;
                }
            }
        }
    }

}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) {

    #student-quiz-results-display {

        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2rem;
        }
        .student-quiz-overall-summary {
            p {
                font-size: 1.75rem;
            }
        }

        .student-quiz-attempt-summary {
            p {
                font-size: 1.25rem;
            }
        }

        #elapsed-time {
            font-size: 1.5rem;
        }

        #download-results-btn {
            a {
                font-size: 1.25rem;
            }
        }

        #student-quiz-results {
            .quiz-results-header {
                h3 {
                    font-size: 1.5rem;
                }
                h4 {
                    font-size: 1.5rem;
                    padding-left: 1.25em;
                }
            }

            .question-list-div {
                border-bottom: 1.5rem solid $secondaryBackgroundColor;
                border-left: 1.5rem solid $secondaryBackgroundColor;
                border-right: 1.5rem solid $secondaryBackgroundColor;
                padding: 1.5rem;

                .question-card {
                    margin-bottom: 3rem;
                    .question-header {
                        display: flex;
                        justify-content: space-between;
                        h4 {
                            font-size: 1.5rem;
                        }
                        .correct-mark {
                            color: #34A400;
                            font-size: 1.75rem;
                        }
                        .incorrect-mark {
                            color: #FF0000;
                            font-size: 1.75rem;
                        }
                    }
                    .question {
                        font-size: 1.25rem;
                    }
                    .question-details {
                        .question-choices {
                            .choice {
                                p {
                                    font-size: 1.25rem;
                                }
                                .banner-div {
                                    p {
                                        font-size: 1.25rem;
                                    }
                                    .correct-banner, .incorrect-banner, .empty-banner {
                                        height: 1.75rem;
                                        width: 7rem;
                                    }
                                }
                            }
                        }
                    }
                }          
            }
        }
        .back-btn-div {

            .back-btn {
                width: 7rem;
                padding: 0.2rem 0.5rem;

                span {
                    font-size: 1.25rem;
                }
            }
        }
    }

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) {


}

// Large devices (desktops, 992px and above)
@media (min-width: 992px) {

    #student-quiz-results-display {

        h1 {
            font-size: 3.25rem;
        }

        h2 {
            font-size: 2.75rem;
        }
        .student-quiz-overall-summary {
            p {
                font-size: 2.25rem;
            }
        }

        .student-quiz-attempt-summary {
            p {
                font-size: 1.5rem;
            }
        }


        #elapsed-time {
            font-size: 2rem;
        }

        #download-results-btn {
            a {
                font-size: 1.5rem;
            }
        }

        #student-quiz-results {
            .quiz-results-header {
                h3 {
                    font-size: 2rem;
                }
                h4 {
                    font-size: 2rem;
                    padding-left: 1em;
                }
            }

            .question-list-div {
                border-bottom: 1.5rem solid $secondaryBackgroundColor;
                border-left: 1.5rem solid $secondaryBackgroundColor;
                border-right: 1.5rem solid $secondaryBackgroundColor;
                padding: 1.5rem;

                .question-card {
                    margin-bottom: 3rem;
                    .question-header {
                        h4 {
                            font-size: 2rem;
                        }
                        .correct-mark {
                            color: #34A400;
                            font-size: 2rem;
                        }
                        .incorrect-mark {
                            color: #FF0000;
                            font-size: 2rem;
                        }
                    }
                    .question {
                        font-size: 1.6rem;
                    }
                    .question-details {
                        .question-choices {
                            .choice {
                                p {
                                    font-size: 1.6rem;
                                }
                                .banner-div {
                                    p {
                                        font-size: 1.6rem;
                                    }
                                    .correct-banner, .incorrect-banner, .empty-banner {
                                        height: 2.25rem;
                                        width: 9rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .back-btn-div {

            .back-btn {
                width: 9rem;

                span {
                    font-size: 1.6rem;
                }
            }
        }
    }

}

// Extra large devices (large desktops, 1200px and above)
@media (min-width: 1200px) {

    #student-quiz-results-display {

        h1 {
            font-size: 3.5rem;
        }

        h2 {
            font-size: 2.75rem;
        }
        #student-quiz-results {

            .question-list-div {
                border-bottom: 1.75rem solid $secondaryBackgroundColor;
                border-left: 1.75rem solid $secondaryBackgroundColor;
                border-right: 1.75rem solid $secondaryBackgroundColor;
                padding: 2rem 4rem;
                margin-bottom: 3rem;
                .question-card {
                    margin-bottom: 4rem;
                    .question-header {
                        .correct-mark {
                            color: #34A400;
                            font-size: 3rem;
                        }
                        .incorrect-mark {
                            color: #FF0000;
                            font-size: 3rem;
                        }
                    }
                }
            }
        }
        .back-btn-div {

            .back-btn {
                width: 9rem;

                span {
                    font-size: 1.6rem;
                }
            }
        }
    }

}