@import '../../index.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
    .multi-users-container {
      padding: 2rem;
      h1 {
        color: $primaryTextColor;
        font-family: $primaryFont;
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      .clear-btn {
        background-color: red;
        color: white;
        font-size: 1.4rem;
        padding: 0.5rem;
      }
      .users-add-list {
        margin-top: 1rem;
        background-color: $secondaryBackgroundColor;
        padding: 1rem;
        h2 {
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 2rem;
          margin-bottom: 1rem;
        }
        .usernames {
          height: 50rem;
          padding: 1rem;
          overflow: scroll;
          p {
            font-size: 1.2rem;
          }
        }
        
      }
      .add-multi-users-btn {
        background-color: $buttonColor;
        margin-top: 1rem;
        color: white;
        padding: 1rem;
        font-size: 1.4rem;
      }
      .dl-template {
        a {
          button {
            background-color: $buttonColor;
            color: white;
            padding: 1rem;
            font-size: 1.4rem;
          }
        }
      }
      .csv-btn {
            background-color: $secondaryBackgroundColor;
            color: white;
            padding: 1rem;
            font-size: 1.4rem;
            margin-top: 1rem;
      }
    }
  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 
    .multi-users-container {
      h1 {
        font-size: 3rem;
      }
      .clear-btn {
        font-size: 1.6rem;
      }
      .users-add-list {
        h2 {
          font-size: 3rem;
        }
        .usernames {
          p {
            font-size: 1.6rem;
          }
        }
        
      }
      .add-multi-users-btn {
        font-size: 1.6rem;
      }
      .dl-template {
        a {
          button {
            font-size: 1.6rem;
          }
        }
      }
      .csv-btn {
            font-size: 1.6rem;
      }
    }
   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
      .multi-users-container {
        width: 50%;
      }
   }