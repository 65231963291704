@import '../../index.scss';
@import '../components.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {
    #lessons-dash {
        width: 90%;
        margin: 0 auto;
        margin-top: 5%;
        h1,
        h2,
        h3,
        p {
            font-family: $primaryFont;
            color: $primaryTextColor;
        }

        h1 {
            font-size: 1.75rem;
            margin-bottom: 1rem;
        }

        h2 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        #no-lessons-header {
            font-size: 1.25rem;
            color: $primaryHoverColor;
        }

        #lessons-display {
            #lessons-row {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .lesson-card {
                    width: 45%;
                    background-color: $secondaryBackgroundColor;
                    padding: 1rem;
                    margin-bottom: 1rem;
                    margin-right: 1rem;

                    h3 {
                        font-size: 1.25rem;
                    }

                    p {
                        font-size: 1rem;
                        margin: .5rem 0;
                    }
                }
                .lesson-card:hover {
                    background-color: $primaryHoverColor;
                    cursor: pointer;
                }
            }
        }
        #show-more-div {
            display: flex;
            justify-content: center;
            margin: 1rem 0;
            align-self: center;
            #show-more-btn, #show-less-btn  {
              background-color: $buttonColor;
              width: 7rem;
              padding: 0.5rem 0.5rem;
              border-radius: 0.5rem;
              span {
                color: $primaryTextColor;
                font-family: $primaryFont;
                font-size: 1rem;
              }
            }
            #show-more-btn {
              margin-right: 0.5rem;
            }
            #show-less-btn {
              margin-left: 0.5rem;
            }
        }
    }
}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) {

    #lessons-dash {

        h1 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        h2 {
            font-size: 1.75rem;
            margin-bottom: 1rem;
        }

        #no-lessons-header {
            font-size: 1.5rem;
        }

        #lessons-display {
            #lessons-row {

                .lesson-card {
                    margin-right: 2rem;

                    h3 {
                        font-size: 1.5rem;
                    }

                    p {
                        font-size: 1.25rem;

                    }
                }
            }
        }
        #show-more-div {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
            #show-more-btn, #show-less-btn  {
              width: 9rem;
              span {
                font-size: 1.25rem;
              }
            }
          }
    }

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) {

    #lessons-dash {
        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2rem;
        }

        #no-lessons-header {
            font-size: 1.75rem;
        }

        #lessons-display {
            #lessons-row {
                .lesson-card {
                    width: 43%;

                    h3 {
                        font-size: 1.75rem;
                    }

                    p {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

}

// Large devices (desktops, 992px and above)
@media (min-width: 992px) {

    #lessons-dash {

        h1 {
            font-size: 3.5rem;
            margin-bottom: 2.2rem;
        }

        h2 {
            font-size: 2.5rem;
            margin-bottom: 2.3rem;
        }

        #lessons-display {
            #lessons-row {
                .lesson-card {
                    padding: 2rem;
                    margin-bottom: 4rem;
                    width: 28%;
                    h3 {
                        font-size: 2rem;
                    }

                    p {
                        font-size: 1.6rem;
                    }
                }
            }
        }
        #show-more-div {
            margin-top: 0rem;
          }
    }

}

// Extra large devices (large desktops, 1200px and above)
@media (min-width: 1200px) {

}

// Extra large devices (large desktops, 1420px and above)
@media (min-width: 1420px) {
  
}