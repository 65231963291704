@import '../../index.scss';
// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
    
  #pdf-dl-btn, #remove-cert-btn {
    color: $primaryTextColor;
    background-color: $buttonColor;
    padding: 1rem;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    margin-right: 2rem;
  }
   
    .cert {
        display: flex;
        flex-direction: column;
        text-align: center;        
        background-color: white;
        color: black;
        padding: 2rem;
        .outer-border {
          border: 2px solid #DE9B72;
          height: 100%;
          width: 100%;
          padding: 6px;
          margin: 0 auto;
        }
      
        .mid-border {
          border: 6px solid #DE9B72;
          height: 100%;
          width: 100%;
          padding: 6px;
          margin: auto;
        }
      
        .inner-border {
          position: relative;
          border: 2px solid #DE9B72;
          height: 100%;
          width: 100%;
          margin: auto;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      
        .corner-decoration {
          position: absolute;
          width: 3em;
          margin: -3px;
        }
      
        .corner-decoration.corner-left-top {
          left: 0;
          top: 0;
        }
      
        .corner-decoration.corner-right-top {
          top: 0;
          right: 0;
          transform: scaleX(-1);
        }
      
        .corner-decoration.corner-right-bottom {
          right: 0;
          bottom: 0;
          transform: scale(-1);
        }
      
        .corner-decoration.corner-left-bottom {
          left: 0;
          bottom: 0;
          transform: scaleY(-1);
        }
      
        .vertical-decoration {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          width: 11em;
        }
      
        .vertical-decoration.top {
          top: 0;
        }
      
        .vertical-decoration.bottom {
          bottom: 0;
          transform: scaleY(-1);
        }
      
        #q-logo-img {
          width: 30rem;
          margin-bottom: 10rem;
        }
      
        h1 {
          font-size: 4rem;
        }
      
        p {
          margin-top: 2rem;
          margin-bottom: 2rem;
          font-size: 2rem;
      
          #cert-name {
            font-family: 'Cedarville Cursive', cursive;
            font-size: 4rem;
          }
      
          #crs-name {
            margin-top: 2rem;
            font-size: 3rem;
          }
        }
      
        .sigs {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 3rem;
      
          #comp-date {
            margin-right: 20rem;
            margin-left: 9rem;
          }
      
          p {
            span {
              font-family: 'Cedarville Cursive', cursive;
              font-size: 2.5rem;
              text-decoration: underline;
            }
          }
        }
      
        #watermark {
          height: 10rem;
          width: 10rem;
          align-self: center;
          margin-top: 4rem;
        }
      }
  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    #pdf-dl-btn, #remove-cert-btn {
      font-size: 1.2rem;
    }
    
  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    #pdf-dl-btn, #remove-cert-btn {
      font-size: 1.4rem;
    }

   }
  
  // Large devices (desktops, 992px and below)
  @media (min-width: 992px) { 

    #pdf-dl-btn, #remove-cert-btn {
      font-size: 1.6rem;
    }

   }
  
  // Extra large devices (large desktops, 1200px and below)
  @media (min-width: 1200px) { 
  
    }