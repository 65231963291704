@import '../../index.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

    nav {
      
      ul {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        a {
          margin: 1.5rem;
          text-decoration: none;
          color: $primaryTextColor;
          text-align: center;
          font-size: 1rem;

          span {
            .MuiSvgIcon-root {
              color: $primaryIconColor;
            }
         }
        }
      }

    }

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    nav {
      
      ul {

        a {
          margin: 1.5rem;
          font-size: 1.5rem;

          span {
             .MuiSvgIcon-root {
               font-size: 3rem;
             }
          }
        }
      }
    }
    
   }
  
  // Large devices (desktops, 992px and below)
  @media (min-width: 992px) { 

   }
  
  // Extra large devices (large desktops, 1200px and below)
  @media (min-width: 1200px) { 
  
    }