@import '../../index.scss';
@import '../components.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

    #remove-headset-words {
        margin-top: 2rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
        color: $primaryTextColor;
        font-family: $primaryFont;

        h2 {
            font-size: 1.5rem;
        }

        #remove-headset-words-btn-div {
            width: 20rem;

            button {
                background: $buttonColor;
                border-radius: 0.5rem;
                margin-top: 1rem;
                width: 10rem;
                height: 2.5rem;
                align-self: center;

                span {
                    color: $primaryTextColor;
                    font-family: $primaryFont;
                    font-size: 1rem;
                }
            }
        }
    }
}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) {

    #remove-headset-words {
        h2 {
            font-size: 1.75rem;
        }

        #remove-headset-words-btn-div {
            button {
                height: 2.75rem;
                width: 12rem;
                span {
                    font-size: 1.25rem;
                }
            }
        }
    }

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) {

    #remove-headset-words {
        h2 {
            font-size: 2rem;
        }

        #message {
            margin-bottom: 1rem;
            font-size: 2rem;
        }

        #remove-headset-words-btn-div {
            button {
                margin-top: 1rem;
                height: 2.75rem;

                span {
                    font-size: 1.25rem;
                }
            }
        }
    }
}

// Large devices (desktops, 992px and below)
@media (min-width: 992px) {

    #remove-headset-words {

        #remove-headset-words-btn-div {
            button {
                margin-top: 1rem;
                height: 2.75rem;
                span {
                    font-size: 1.25rem;
                }
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and below)
@media (min-width: 1200px) {}