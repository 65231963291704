@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

  #lessons-div {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
    color: $primaryTextColor;
    font-family: $primaryFont;
    div h1 {
      font-size: 1.5rem;
    }
    #lesson-action {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      #search-text, #search-tag-text {
        background-color: $formInputBackgroundColor;
        color: $primaryTextColor;
        font-family: $primaryFont;
        padding: 0.5rem;
        font-size: 1rem;
        margin-right: 1rem;
        width: 20rem;
      }
      #search-tag-text {
        display: none;
      }
    }
    #lessons-titles {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: $tertiaryBackgroundColor;
      margin-top: 1rem;
      padding: 0.5rem;
      #lesson-name-header {
        width: 35%;
      }
      #secondary-lesson-headers {
        justify-content: space-around;
        align-items: center;
        width: 65%;
        margin-right: 0.25rem;
        #lesson-publish-header {
          width: 33.333%;
          text-align: center;
        }
        #lesson-revision-header {
          width: 33.333%;
          text-align: center;
        }
        #lesson-more-header {
          width: 33.333%;
          text-align: center;
        }
        #lesson-moduleName-header {
            display: none;
          }
          #lesson-tags-header {
            display: none;
          }
        #lesson-desc-header {
          display: none;
        }
      }
    }
    #lesson-info-div {
      display: flex;
      justify-content: space-between;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      #lesson-name {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        width: 35%;
      }
      #secondary-lesson-data {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 65%;
        #lesson-publish {
          width: 33.333%;
          display: flex;
          align-items: center;
          justify-content: center;
          .lesson-publish-icon, .lesson-unpublish-icon {
            font-size: 1.5rem;
            margin-right: 0.25rem;
            border-radius: 50%;
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .lesson-publish-icon{
            background-color: $publishedBackgroundColor;
          }
          .lesson-unpublish-icon {
            background-color: $unpublishedBackgroundColor;
          }
        }
        #lesson-revision {
          width: 33.333%;
          display: flex;
          align-items: center;
          justify-content: center;
          .lesson-original-icon, .lesson-revised-icon {
            font-size: 1.5rem;
            margin-right: 0.25rem;
            border-radius: 50%;
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .lesson-revised-icon {
            background-color: green;
          }
          .lesson-original-icon {
            background-color: red;
          }
        }
        #lesson-moduleName {
            display: none;
          }
          #lesson-tags {
            display: none;
          }
        #lesson-desc {
          display: none;
        }
        .lesson-more {
          display: flex;
          width: 33.33%;
          text-align: center;
          justify-content: center;
          .more-circle {
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            border: 0.1rem solid $primaryTextColor;
            display: flex;
            svg {
              font-size: 1rem;
              position: relative;
              top: 9.25%;
              left: 15%
            }
            .more-modal {
              position: relative;
              z-index: 10;
              right: 1.25rem;
              background-color: $modalBackgroundColor;
              border-radius: 1.5rem;
              top: 1.75rem;
              height: 9rem;
              .modal-ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                .modal-li {
                  list-style: none;
                  width: 5rem;
                  margin-top: 1rem;
                }
                .modal-li:hover{
                  color: $primaryHoverColor;
                  cursor: pointer;
                }
              }
            }
          }
          .more-circle:hover{
            cursor: pointer;
            border: 0.1rem solid $primaryHoverColor;
            svg {
              color: $primaryHoverColor;
              path {
                color: $primaryHoverColor;
              }
            }
          }
          .edit-btn {
            background-color: $buttonColor;
            width: 3rem;
            padding: 0.1rem 0.5rem;
            border-radius: 0.5rem;
            span {
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-size: 1rem;
            }
          }
        }
      }
    }
    .select-div:hover {
      background-color: $primaryHoverColor;
      cursor: pointer;
    }
    
    .lesson-line {
      border: 1px solid #5A6770;
    }
    #show-more-div {
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      #show-more-btn, #show-less-btn  {
        background-color: $buttonColor;
        width: 7rem;
        padding: 0.5rem 0.5rem;
        border-radius: 0.5rem;
        span {
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 1rem;
        }
      }
      #show-more-btn {
        margin-right: 0.5rem;
      }
      #show-less-btn {
        margin-left: 0.5rem;
      }
    }
    .message {
      text-align: center;
    }
  } 

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    #lessons-div {
      div h1 {
        font-size: 3rem;
      }
      #lesson-action {
        #search-text, #search-tag-text {
          font-size: 1.25rem;
          width: 25rem;
        }
        #add-lesson-btn {
          height: 3rem;
          width: 13rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
      #lessons-titles {
        padding: 1rem;
        h2 {
          font-size: 1.25rem;
        }
        #lesson-name-header {
          width: 20%;
        }
        #secondary-lesson-headers {
          width: 80%;
          margin: 0;
          #lesson-publish-header {
            width: 33.333%;
            text-align: center;
          }
          #lesson-revision-header {
            display: block;
            width: 33.333%;
          }
          #lesson-more-header {
            width: 33.333%;
            text-align: center;
          }
        }
      }
      #lesson-info-div {
        margin-left: 1rem;
        margin-right: 1rem;
        p {
          font-size: 1.25rem;
        }
        #lesson-name {
          margin-top: 1rem;
          margin-bottom: 0.5rem;
          width: 20%;
        }
        #secondary-lesson-data {
          display: flex;
          align-items: center;
          width: 80%;
          #lesson-publish {
            width: 33.333%;
            .lesson-publish-icon, .lesson-unpublish-icon {
              font-size: 1.75rem;
              margin-right: 0.25rem;
              height: 1.75rem;
              width: 1.75rem;
            }
          }
          #lesson-revision {
            width: 33.333%;
            .lesson-original-icon, .lesson-revised-icon {
              font-size: 1.75rem;
              margin-right: 0.25rem;
              height: 1.75rem;
              width: 1.75rem;
            }
          }
          .lesson-more {
            width: 33.333%;
            text-align: center;
            .more-circle {
              height: 2rem;
              width: 2rem;
              svg {
                font-size: 1.25rem;
                top: 15%;
                left: 18%;
              }
              .more-modal {
                right: 1.25rem;
                top: 2rem;
                height: 10.5rem;
                padding: 0 1rem;
                .modal-ul {
                  .modal-li {
                    font-size: 1.25rem;
                    width: 6rem;
                    margin-top: 1rem;
                  }
                  .modal-li:hover{
                    color: $primaryHoverColor;
                    cursor: pointer;
                  }
                }
              }
            } 
            .edit-btn {
              width: 5rem;
              span {
                font-size: 1.25rem;
              }
            }
          }
        }
      }
      #show-more-div {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        #show-more-btn, #show-less-btn  {
          width: 9rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    #lessons-div {
      #add-lesson-btn {
        span {
          font-size: 1.5rem;
        }
      }
      #lessons-titles {
        h2 {
          font-size: 1.5rem;
        }
          #secondary-lesson-headers {
            #lesson-publish-header {
              width: 25%;
              text-align: center;
            }
            #lesson-revision-header {
              display: block;
              width: 25%;
            }
            #lesson-moduleName-header {
                display: block;
                width: 25%;
                text-align: center;
              }
            #lesson-more-header {
              width: 25%;
              text-align: center;
            }
          }
      }
      #lesson-info-div {
        p {
          font-size: 1.5rem;
        }
          #secondary-lesson-data {
            #lesson-publish {
              width: 25%;
              .lesson-publish-icon, .lesson-unpublish-icon {
                font-size: 2rem;
                margin-right: 0.25rem;
                height: 2rem;
                width: 2rem;
              }
            }
            #lesson-revision {
              width: 25%;
              .lesson-original-icon, .lesson-revised-icon {
                font-size: 2rem;
                margin-right: 0.25rem;
                height: 2rem;
                width: 2rem;
              }
            }
            #lesson-moduleName {
                display: block;
                width: 25%;
                text-align: center;
              }
            .lesson-more {
              width: 25%;
              text-align: center;
              .more-circle {
                height: 2.25rem;
                width: 2.25rem;
                svg {
                  font-size: 1.5rem;
                  top: 15%;
                  left: 17%;
                }
                .more-modal {
                  right: 1.25rem;
                  top: 2rem;
                  height: 12rem;
                  .modal-ul {
                    .modal-li {
                      font-size: 1.5rem;
                      width: 7rem;
                      margin-top: 1rem;
                    }
                    .modal-li:hover{
                      color: $primaryHoverColor;
                      cursor: pointer;
                    }
                  }
                }
              } 
            }
          }
      }
      
    }

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

    #lessons-div {
      #lesson-action {
        #search-tag-text {
          display: block;
        }
      }
      #lessons-titles {
        #lesson-name-header {
          width: 20%;
        }
        #secondary-lesson-headers {
          width: 80%;
          #lesson-publish-header {
            width: 16.667%;
          }
          #lesson-revision-header {
            width: 16.667%;
          }
          #lesson-moduleName-header {
            width: 16.667%;
          }
          #lesson-tags-header {
            display: block;
            width: 16.667%;
            text-align: center;
          }
          #lesson-desc-header {
            display: block;
            width: 16.667%;
            text-align: center;
          }
          #lesson-more-header {
            width: 16.667%;
          }
        }
      }
      #lesson-info-div {
        #lesson-name {
          width: 20%;
          align-self: center;
        }
        #secondary-lesson-data {
          width: 80%;
          padding: 1rem 0;
          #lesson-publish {
            width: 16.667%;
          }
          #lesson-revision {
            width: 16.667%;
          }
          #lesson-moduleName {
            width: 16.667%;
          }
          #lesson-tags {
            width: 16.667%;
            display: flex;
            flex-wrap: wrap;
            .lesson-tag-name {
              list-style-type: none;
              font-size: 1.5rem;
            }
          }
          #lesson-desc {
            width: 16.667%;
            display: block;
            text-align: center;
          }
          .lesson-more {
            width: 16.667%;
          }
        }
      }
    }

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
  
   }