@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {
  #usecase-selector {
      margin-top: 1rem;
      margin-left: 1rem;
    label {
      color: $primaryTextColor;
      font-family: $primaryFont;
      font-size: 3.5rem;
      span {
        color: red;
        font-size: 3.5rem;
      }
    }
    select {
      margin-left: 1rem;
      height: 3.5rem;
      font-size: 2rem;
      background-color: $formInputBackgroundColor;
      color: $primaryTextColor;
      font-family: $primaryFont;
      border: none;
      border-radius: .5rem;
      padding: 0.5rem;
    }
    h1 {
      margin-top: 3rem;
      color: $primaryTextColor;
      font-family: $primaryFont;
      font-size: 3.5rem;
    }
  }
   #page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.1rem;
    h1 {
      font-size: 3rem;
      color: $primaryTextColor;
      font-family: $primaryFont;
      margin-bottom: 2rem;
      margin-left: 1rem;
    }
    #search-filter {
      display: flex;
      align-items: center;
      .search-input {
        height: 4.1rem;
        width: 21.2rem;
        font-size: 1.6rem;
        padding: 1.1rem;
        color: $primaryTextColor;
        background-color: $formInputBackgroundColor;
        margin-right: 1.6rem;
      }
      .filter-div {
        position: relative;
        margin-right: 2.1rem;
        .filter-button {
          height: 4.1rem;
          width: 4.1rem;
          border-radius: 0.5rem;
          background-color: $submitButtonColor;
          svg {
            color: #F5F5F5;
            width: 2.171rem;
            height: 1.9rem;
          }
        }
      }
    }  
  }
  #tags-page {
      margin-left: 1rem;
      margin-top: 1rem;
      margin-right: 1rem;
      display: flex;
      justify-content: space-between;
      .dynamic-lists {
        display: flex;
        #dynamic-list-tier-1, #dynamic-list-tier-2, #dynamic-list-tier-3 {
          display: flex;
          flex-direction: column;
          margin-left: 3rem;
          width: 20rem;
          .dynamic-list-header {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1.8rem;
            background-color: $tertiaryBackgroundColor;
            padding: 1.6rem 2.3rem;
          }
          #qp-list-tier-1, #qp-list-tier-2, #qp-list-tier-3 {
            border: 0.3rem solid $tertiaryBackgroundColor;
            background-color: $primarybackgroundColor;
            height: 306px;
            overflow-y: auto;
            li {
              position: relative;
              margin-left: 2rem;
              span {
                font-size: 4rem;
                position: absolute;
                left: 120px;
              }
              i {
                color: green;
                position: absolute;
                left: 105px;
              }
            }
          }
          .add-tag-div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 1.1rem;
            .message {
              margin-top: 0rem;
              margin-bottom: 1rem;
            }
            .add-tag-btn {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 0.5rem;
              svg {
                height: 2.2rem;
                width: 2.2rem;
                color: #2197D6;
                margin-right: 0.9rem;
              }
              svg:hover {
                color: green;
                cursor: pointer;
              }
              p {
                color: #2197D6;
                font-family: $primaryFont;
                font-size: 1.5rem;
              }
            }
            .add-tag-input {
              font-size: 1.5rem;
              padding: 0.5rem;
              color: $primaryTextColor;
              font-family: $primaryFont;
              background-color: $formInputBackgroundColor;
              width: 17rem
            }
            .new-tag-div-tier-1, .new-tag-div-tier-2, .new-tag-div-tier-3 {
              display: flex;
              align-items: center;
              margin-top: 0.5rem;
              .remove-tag-btn {
                font-size: 1.25rem;
                color: $primaryTextColor;
                font-family: $primaryFont;
                background-color: red;
                padding: 0.5rem;
                margin-left: 0.5rem;
                i {
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }
      #selected-tag {
        margin-right: 1.1rem;
        margin-top: 1.8rem;
        background-color: $secondaryBackgroundColor;
        padding: 2.5rem 1.75rem;
        border-radius: 0.5rem;
        width: 52.8rem;
        height: 59.9rem;
        #results-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 2.28rem;
          h1 {
            color: $primaryTextColor;
            font-family: $primaryFont;
          }
          #results-count {
            font-size: 2rem;
            margin: 0;
          }
          .filter-div {
            position: relative;
            .filter-button {
              height: 3.5rem;
              width: 3.5rem;
              border-radius: 0.5rem;
              background-color: $submitButtonColor;
              svg {
                color: #F5F5F5;
                width: 1.854rem;
                height: 1.622rem;
              }
            }
            #filter-modal {
              .filter-type-div {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 0.5rem;
                padding: 1rem 0;
                .checkbox-container {
                  display: block;
                  position: relative;
                  cursor: pointer;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                  font-size: 1.6rem;
                  width: 10.5rem;
                  color: $primaryTextColor;
                  font-family: $primaryFont;
                }

                /* Hide the browser's default checkbox button */
                .checkbox-container .checkbox-input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  width: auto;
                }

                /* Create a custom checkbox button */
                .checkmark {
                  position: absolute;
                  top: -0.2rem;
                  left: 12rem;
                  height: 2rem;
                  width: 2rem;
                  background-color: #292B2C;
                  border: .2rem solid $primarybackgroundColor;
                  border-radius: 50%;
                }

                /* On mouse-over, add a grey background color */
                .checkbox-container:hover .checkbox-input ~ .checkmark {
                  border-color: $primaryTextColor;
                  background-color: #2196F3;
                }
                
                /* When the checkbox button is checked, add a blue background */
                .checkbox-container .checkbox-input:checked ~ .checkmark {
                  background-color: #2196F3;
                  border-color: $primaryTextColor;
                }
                
                /* Create the indicator (the dot/circle - hidden when not checked) */
                .checkmark:after {
                  content: "";
                  position: absolute;
                  display: none;
                }
                
                /* Show the indicator (dot/circle) when checked */
                .checkbox-container .checkbox-input:checked ~ .checkmark:after {
                  display: block;
                }
                
                /* Style the indicator (dot/circle) */
                .checkbox-container .checkmark:after {
                  top: 0.2rem;
                  left: 0.35rem;
                  width: 0.8rem;
                  height: 0.8rem;
                  content: '✓';
                }
              }
            }
          }
        }
        #results-titles {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $primaryTextColor;
          font-family: $primaryFont;
          background-color: $tertiaryBackgroundColor;
          padding: 1.7rem;
          padding-left: 0;
          #primary-titles {
           display: flex;
           justify-content: flex-start;
           width: 80%;
           #item-type-title {
            width: 30%;
            text-align: center;
            font-size: 1.8rem;
           }
           #item-name-title {
            width: 70%;
            font-size: 1.8rem;
            margin-left: 3rem;
           }
          }
          #secondary-titles {
            width: 20%;
            display: flex;
            justify-content: flex-end;
            font-size: 1.8rem;
            margin-right: 1.25rem;
            #item-more-title {
              font-size: 1.8rem;
            }
          }
        }
        #list {
          height: 43rem;
          overflow-y: auto;
          #item-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $primaryTextColor;
            font-family: $primaryFont;
            padding: 1.7rem;
            padding-left: 0;
            .primary-item {
              display: flex;
              justify-content: flex-start;
              width: 80%;
              .item-type {
                width: 30%;
                text-align: center;
                font-size: 1.6rem;
              }
              .item-name {
                width: 70%;
                font-size: 1.6rem;
                margin-left: 3rem;
              }
            }
            .secondary-item {
              width: 20%;
              display: flex;
              justify-content: flex-end;
              .item-more {
                svg {
                  font-size: 2rem;
                  cursor: pointer;
                }
                svg:hover {
                  color: $primaryHoverColor
                }
              }
            }
          }
          .item-line {
            border: 1px solid #5A6770;
          }
        }
      }
    }
  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

   

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 

    
      
   }
// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

  #tags-div {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
    color: $primaryTextColor;
    font-family: $primaryFont;
    div h1 {
      font-size: 1.5rem;
    }
    #tag-action {
      display: flex;
      align-items: center;
      #search-text {
        background-color: $formInputBackgroundColor;
        color: $primaryTextColor;
        font-family: $primaryFont;
        padding: 0.5rem;
        font-size: 1rem;
        margin-right: 1rem;
      }
      #add-tag-btn {
        color: $primaryTextColor;
        background-color: $buttonColor;
        padding: .2rem 0;
        width: 10rem;
        border-radius: 0.5rem;
        span {
          font-size: 1rem;
        }
      }
    }
    #tags-titles {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: $tertiaryBackgroundColor;
      margin-top: 1rem;
      padding: 0.5rem;
      #tag-name-header {
        width: 20%;
      }
      #secondary-tag-headers {
        align-items: center;
        width: 80%;
        #tag-cust-header {
          display: none;
        }
        #tag-useCase-header {
          display: none;
        }
        #tag-type-header {
          width: 25%;
          text-align: center;
        }
        #tag-sub1-header {
          width: 25%;
          text-align: center;
        }
        #tag-sub2-header {
          width: 25%;
          text-align: center;
        }
        #tag-desc-header {
          display: none;
        }
        #tag-more-header {
          width: 25%;
          text-align: center;
        }
      }
    }
    #tag-info-div {
      display: flex;
      justify-content: space-between;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      align-items: center;
      #tag-name {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        width: 20%;
      }
      #secondary-tag-data {
        display: flex;
        align-items: center;
        width: 80%;
        #tag-cust {
          display: none;
        }
        #tag-useCase {
          display: none;
        }
        #tag-type {
          width: 25%;
          text-align: center;
        }
        #tag-sub1 {
          width: 25%;
          text-align: center;
        }
        #tag-sub2 {
          width: 25%;
          text-align: center;
        }
        #tag-desc {
          display: none;
        }
        #tag-more {
          width: 25%;
          text-align: center;
          .edit-btn {
            background-color: $buttonColor;
            width: 3rem;
            padding: 0.1rem 0.5rem;
            border-radius: 0.5rem;
            span {
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-size: 1rem;
            }
          }
        }
      }
    }
    
    .tag-line {
      border: 1px solid #5A6770;
    }
    #show-more-div {
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      #show-more-btn, #show-less-btn  {
        background-color: $buttonColor;
        width: 7rem;
        padding: 0.5rem 0.5rem;
        border-radius: 0.5rem;
        span {
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 1rem;
        }
      }
      #show-more-btn {
        margin-right: 0.5rem;
      }
      #show-less-btn {
        margin-left: 0.5rem;
      }
    }
  } 

  }
  
 