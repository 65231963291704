@import '../../index.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
    .student-dash {
        .student-dash-h1 {
            font-family: $primaryFont;
            color: $primaryTextColor;
            padding: 1rem;
            font-size: 2rem;
        }
    }
  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 
    

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    .student-dash {
        .student-dash-h1 {
            font-size: 3rem;
        }
    }

   }
  
  // Large devices (desktops, 992px and below)
  @media (min-width: 992px) { 
    
   }
  
  // Extra large devices (large desktops, 1200px and below)
  @media (min-width: 1200px) { 
      
    }

    @media (min-width: 1600px) { 
      
    }