@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

    #student-lessons-display {
        width: 90%;
        margin: 0 auto;
        margin-top: 2rem;

        h3,
        h4,
        h5,
        p {
            font-family: $primaryFont;
            color: $primaryTextColor;
        }

        #student-email-header, #student-email {
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }

        h4 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        .lessons-display-div {
            background-color: $secondaryBackgroundColor;
            padding-bottom: 2rem;
            h5 {
                text-align: center;
                padding: 1rem 0;
                font-size: 1.25rem;
            }
            .lesson-div {
                width: 90%;
                margin: 0 auto;
                background-color: $primarybackgroundColor;
                margin-bottom: 1rem;
                .lesson-info-row {
                    display: flex;
                    padding: 1rem;
                    .lesson-abrev-div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $secondaryBackgroundColor;
                        width: 6rem;
                        height: 5rem;
                        margin-right: 1rem;
                        p {
                            font-size: 1rem;
                        }
                    }
                    .lesson-details {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        p {
                            margin: 0.1rem 0;
                            font-size: 1rem;
                        }
                        .lesson-name {
                            font-size: 1.25rem;
                            font-weight: bold;
                        }
                    }
                }
            }
            .lesson-div:hover {
                background-color: $primaryHoverColor;
                cursor: pointer;
            }
        }

        .back-btn-div {
            text-align: center;

            .back-btn {
                background-color: $buttonColor;
                width: 5rem;
                padding: 0.1rem 0.5rem;
                border-radius: 0.5rem;
                margin: 1rem 0;

                span {
                    color: $primaryTextColor;
                    font-family: $primaryFont;
                    font-size: 1rem;
                }
            }
        }
    }
}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) {

    #student-lessons-display {

        #student-email-header, #student-email {
            font-size: 1.5rem;
        }

        h4 {
            font-size: 2rem;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
        }

        .lessons-display-div {
            h5 {
                font-size: 1.5rem;
            }
            .lesson-div {
                .lesson-info-row {
                    .lesson-abrev-div {
                        width: 7rem;
                        height: 6rem;
                        p {
                            font-size: 1.25rem;
                        }
                    }
                    .lesson-details {
                        p {
                            font-size: 1.25rem;
                        }
                        .lesson-name {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }

        .back-btn-div {

            .back-btn {
                width: 7rem;
                padding: 0.2rem 0.5rem;

                span {
                    font-size: 1.25rem;
                }
            }
        }
    }

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) {


}

// Large devices (desktops, 992px and above)
@media (min-width: 992px) {

    #student-lessons-display {

        #student-email-header, #student-email {
            font-size: 2rem;
        }

        h4 {
            font-size: 3rem;
        }

        .lessons-display-div {
            padding-bottom: 3rem;
            h5 {
                font-size: 2rem;
            }
            .lesson-div {
                width: 97%;
                margin: 0 auto;
                .lesson-info-row {
                    margin: 1rem 0;
                    .lesson-abrev-div {
                        width: 9.25rem;
                        height: 8.5rem;
                        p {
                            font-size: 2rem;
                        }
                    }
                    .lesson-details {
                        p {
                            font-size: 1.6rem;
                        }
                        .lesson-name {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }

        .back-btn-div {

            .back-btn {
                width: 9rem;

                span {
                    font-size: 1.6rem;
                }
            }
        }
    }

}

// Extra large devices (large desktops, 1200px and above)
@media (min-width: 1200px) {

    #student-lessons-display {

        h4 {
            font-size: 3.5rem;
        }

        .lessons-display-div {
            padding-bottom: 4rem;
            .lesson-div {
                width: 98%;
                margin: 0 auto;
            }
        }
    }

}