@import '../../index.scss';
@import '../components.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 

    #add-user-div {
      width: 80%;
      margin: 3rem auto 0 auto;
      h1 {
        color: $primaryTextColor;
        font-family: $primaryFont;
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }
      h2 {
        color: $primaryTextColor;
        font-family: $primaryFont;
        font-size: 2rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
      }
      form {
        #select-role-div {
          display: flex;
          flex-direction: column;
          .select-role-items {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .radio-container {
              display: block;
              position: relative;
              cursor: pointer;
              font-size: 22px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              font-size: 1.25rem;
              width: 10.5rem;
            }
            
            /* Hide the browser's default radio button */
            .radio-container .radio-input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              width: auto;
            }
            
            /* Create a custom radio button */
            .checkmark {
              position: absolute;
              top: -0.2rem;
              left: 8rem;
              height: 2rem;
              width: 2rem;
              background-color: $primarybackgroundColor;
              border: .3rem solid #292B2C;
              border-radius: 50%;
            }
            
            /* On mouse-over, add a grey background color */
            .radio-container:hover .radio-input ~ .checkmark {
              border-color: $primaryTextColor;
              background-color: #2196F3;
            }
            
            /* When the radio button is checked, add a blue background */
            .radio-container .radio-input:checked ~ .checkmark {
              background-color: #2196F3;
              border-color: $primaryTextColor;
            }
            
            /* Create the indicator (the dot/circle - hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }
            
            /* Show the indicator (dot/circle) when checked */
            .radio-container .radio-input:checked ~ .checkmark:after {
              display: block;
            }
            
            /* Style the indicator (dot/circle) */
            .radio-container .checkmark:after {
              top: 0.2rem;
              left: 0.35rem;
              width: 0.8rem;
              height: 0.8rem;
              content: '✓';
            }
            .MuiSvgIcon-root {
              color: $primaryIconColor;
            }
          }
          .add-tag-dropdown {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            #tag-dropdown-header {
              font-size: 2rem;
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-weight: bold;
            }
            #tag-select-header {
              font-size: 1.25rem;
              margin-bottom: 0.5rem;
              margin-top: 0;
            }
            select {
              height: 10rem;
              background-color: $formInputBackgroundColor;
              padding: 1rem;
              option {
                font-size: 1.25rem;
                color: $primaryTextColor;
                font-family: $primaryFont;
              }
              option:hover {
                background-color: $primaryHoverColor;
                cursor: pointer;
              }
            }
          }
        }
        h3, h4 {
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 2rem;
          margin-bottom: 1rem;
          margin-top: 2rem;
        }
        #add-user-first, #add-user-last {
          display: flex;
          flex-direction: column;
          width: 100%;
          label[for='firstName'], label[for='lastName'] {
            font-size: 1.25rem;
            margin: 0;
            span {
              margin-left: 0.25rem;
              color: red;
              font-size: 1.5rem
            }
          }
          input {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            font-size: 1.25rem;
            color: $primaryTextColor;
            font-family: $primaryFont;
          }
        }
        #add-user-email {
          display: flex;
          width: 100%;
          flex-direction: column;
          label {
            font-size: 1.25rem;
            margin: 0;
            span {
              margin-left: 0.25rem;
              color: red;
              font-size: 1.5rem
            }
          }
          input {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            font-size: 1.25rem;
            color: $primaryTextColor;
            font-family: $primaryFont;
          }
        }
        #add-user-location, #add-user-customer {
          display: flex;
          width: 100%;
          flex-direction: column;
          label {
            font-size: 1.25rem;
            margin: 0;
            span {
              margin-left: 0.25rem;
              color: red;
              font-size: 1.5rem
            }
          }
          select {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            height: 2.8rem;
            font-size: 1.5rem;
            background-color: $formInputBackgroundColor;
            color: $primaryTextColor;
            font-family: $primaryFont;
            border: none;
            border-radius: .5rem;
            padding: 0.5rem;
          }
        }
        #add-user-btns {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-top: 2rem;
          #cancel-btn, #invite-btn {
            margin: 0 0.5rem;
            width: 8rem;
            border-radius: 0.5rem;
            span {
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-size: 1.25rem;
            }
          }
          #cancel-btn {
            border-color: $cancelButtonColor;
            background-color: $cancelButtonColor;
          }
          #invite-btn {
            border-color: $submitButtonColor;
            background-color: $submitButtonColor;
          }
        }
      }
      .message {
        text-align: center;
      }
    }

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    #add-user-div {
      width: 90%;
      margin: 2rem auto 0 auto;
      select[name='userRole'] {
        margin-bottom: 3rem;
      }
      form {
        #select-role-div {
          .add-tag-dropdown {
            width: 30rem;
            select {
              height: 15rem;
              option {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
      #add-user-name {
        display: flex;
        justify-content: space-between;
        #add-user-first, #add-user-last {
          display: flex;
          flex-direction: column;
          width: 45%;
          input {
            margin-bottom: 1.5rem;
          }
        }
        input {
          margin-bottom: 1.5rem;
        }
      }
      #add-location-cust-div {
        display: flex;
        justify-content: space-between;
        #add-user-location, #add-user-customer {
          display: flex;
          flex-direction: column;
          width: 45%;
          select {
            margin-bottom: 1.5rem;
          }
        }
      }
      #add-user-email {
        input {
          margin-bottom: 1.5rem;
        }
      }
      #add-user-btns {
        #cancel-btn, #invite-btn {
          margin: 0 0.5rem;
          height: 3rem;
          width: 12rem;
        }
      }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    #add-user-div {
      form {
        #select-role-div {
          .select-role-items {
            .radio-container {
             margin-bottom: 1rem;
             font-size: 1.5rem;
            }
          }
        }
        #add-user-name {
          display: flex;
          justify-content: space-between;
          #add-user-first, #add-user-last {
            display: flex;
            flex-direction: column;
            width: 45%;
            label {
              font-size: 1.5rem;
              width: 45%;
            }
            input {
              font-size: 1.5rem;
              width: 100%
            }
          }
        }
        #add-user-email {
          label {
            font-size: 1.5rem;
            width: 100%;
          }
          input {
            font-size: 1.5rem;
            width: 100%;
          }
        }
        #add-user-location, #add-user-customer {
          label {
            font-size: 1.5rem;
          }
          select {
            height: 3.1rem;
            font-size: 1.5re,;
          }
        }
        #add-user-btns {
          margin-top: 3rem;
          #cancel-btn, #invite-btn {
            width: 14rem;
            height: 4rem;
            span {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 
      #add-user-div {
       width: 53.8rem;
       margin: 0;
       margin-top: 3.5rem;
       margin-left: 4.1rem;
       h1 {
        font-size: 3.5rem;
       }
       h2 {
        margin-top: 2.6rem;
        margin-bottom: 0.95rem;
        font-size: 2.5rem;
       }
       form {
        #select-role-div {
          .select-role-items {
            .radio-container {
              margin: 0;
              margin-top: 1.15rem;
              margin-bottom: 1.15rem;
              font-size: 1.6rem;
              width: 15.3rem;
            }
            
            /* Hide the browser's default radio button */
            .radio-container .radio-input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              width: auto;
            }
            
            /* Create a custom radio button */
            .checkmark {
              position: absolute;
              top: -0.2rem;
              left: 11.8rem;
              height: 2.3rem;
              width: 2.2rem;
              background-color: $primarybackgroundColor;
              border: .3rem solid #292B2C;
              border-radius: 50%;
            }
            
            /* Style the indicator (dot/circle) */
            .radio-container .checkmark:after {
              top: 0.275rem;
              left: 0.4rem;
              width: 0.8rem;
              height: 0.8rem;
              content: '✓';
            }
            .MuiSvgIcon-root {
              color: $primaryIconColor;
              font-size: 1.75rem;
              margin: 0;
            }
          }
          .add-tag-dropdown {
            width: 28rem;
            #tag-dropdown-header {
              font-size: 2.5rem;
            }
          }
        }
        h3, h4 {
          font-size: 2.5rem;
          margin-bottom: 2.1rem;
          margin-top: 3.55rem;
        }
        #add-user-btns {
          margin-top: 3.7rem;
          #cancel-btn, #invite-btn {
            width: 15rem;
            height: 4.1rem;
            margin: 0 1rem;
            span {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
    
  }