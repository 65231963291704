@import '../../index.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 

  .active {
    background-color: #5250AA;
    width: 60%;
    border-radius: 1rem;
    box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.8);
    padding: 0.5rem;
  }

  .active-font {
    color: #5250AA;
    font-weight: bold;
    font-size: large;
    border-radius: 1rem;
    background-color: white;
    padding: 1rem;
  }

    .dashboard {
      color: $primaryTextColor;
      font-family: $primaryFont;
      background-color: $secondaryBackgroundColor;
      display: flex;
      flex-direction: column;
      width: 95%;
      margin: auto;
      margin-top: 2rem;
      padding: 1rem;
      .dash-header {
        h2 {
          font-size: 1.4rem;
        }
        .filters {
          .filter {
            margin-top: 1rem;
            label {
              font-size: 1.2rem;
            }
            .filter-inputs {
              display: flex;
              margin-top: 0.5rem;
              input {
                background: $formInputBackgroundColor;
                border-radius: .5rem;
                font-size: 1.2rem;
                padding: .5rem;
                color: $primaryTextColor;
                font-family: $primaryFont;
              }
              .clear-filter {
                background-color: red;
                color: white;
                padding: 0.25rem;
                font-size: 1.2rem;
              }
            }
          }
        }
        h1 {
          margin: 2rem 0;
          font-size: 2rem;
        }
      }
      .no-class {
        padding: 1rem;
        font-size: 1.2rem;
      }
      .unit-header {
        display: flex;
        width: 100%;
        background-color: $primarybackgroundColor;
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.8);
        position: relative;
        margin-bottom: 1rem;
        .unit-title {
          width: 50%
        }
        .unit-header-dd {
          width: 25%;
          text-align: center;
          .svg-inline--fa {
            color: white;
            height: 2rem;
            width: 2rem;
          }
          .svg-inline--fa:hover {
            cursor: pointer;
          }
        }
        .unit-dd {
          position: absolute;
          top: 100%;
          left: 5%;
          display: flex;
          width: 90%;
          background-color: $primarybackgroundColor;
          padding: 1rem;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.8);
          z-index: 2;
          .unit-details {
            background-color: $secondaryBackgroundColor;
            width: 95%;
            margin: auto;
            padding: 1rem;
            border-radius: 1rem;
            box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.8);
            .student-info {
              background-color: $primarybackgroundColor;
              width: 95%;
              margin: auto;
              padding: 1rem;
              border-radius: 1rem;
              box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.8);
              margin-top: 1rem;
              .stud-metrics {
                display: flex;
                justify-content: space-between;
                .student-conf {
                  p {
                    span {
                      font-weight: bold;
                    }
                  }
                }
              }
              .datachart {
                .student-progress-chart, .course-progress-chart {
                  display: flex;  
                  width: 100%;
                  canvas {
                    width: 90%;
                }
                .cert-btn-divs {
                    width: 10%;
                    z-index: 10;
                    .cert-btn {
                        margin-top: -50%;
                        button {
                            color: $primaryTextColor;
                            background-color: $buttonColor;
                            font-size: 1.1rem;
                            padding: 0.5rem;
                        }
                    }
                    .cert-btn button:hover {
                      cursor: pointer;
                    }
                }
                }
              }
              .module-info {
                background-color: $secondaryBackgroundColor;
                width: 95%;
                margin: auto;
                padding: 1rem;
                border-radius: 1rem;
                box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.8);
                margin-top: 1rem;
                .lesson-info {
                  background-color: $primarybackgroundColor;
                  width: 95%;
                  margin: auto;
                  padding: 1rem;
                  border-radius: 1rem;
                  box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.8);
                  margin-top: 1rem;
                }
              }
            }
          }
        }
      }
    }
    
  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 
    .dashboard {
      .dash-header {
        .filters {
          display: flex;
          flex-wrap: wrap;
          .filter {
            margin-right: 1rem;
            .filter-inputs {
              margin-top: 1rem;
            }
          }
        }
      }
      .no-class {
        font-size: 1.4rem;
      }
      .unit-header {
        
        .unit-title {
          
        }
        .unit-header-dd {
          
          .svg-inline--fa {
            
          }
        }
        .unit-dd {
          
          .unit-details {
           
            .student-info {
              
              .datachart {
                .student-progress-chart, .course-progress-chart {
                  canvas {
                      width: 90%;
                  }
                  .cert-btn-divs {
                      width: 10%;
                      .cert-btn {
                          margin-top: -35%;
                          button {
                              font-size: 1.1rem;
                              padding: 0.5rem;
                          }
                      }
                  }
              }
              }
              .module-info {
               
                .lesson-info {
                 
                }
              }
            }
          }
        }
      }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    .dashboard {
      .dash-header {
        h2 {
          font-size: 1.6rem;
        }
        .filters {
          display: flex;
          flex-wrap: wrap;
          .filter {
            label {
              font-size: 1.4rem;
            }
            margin-right: 1rem;
            .filter-inputs {
              margin-top: 1rem;
              input {
                border-radius: .5rem;
                font-size: 1.2rem;
                padding: .5rem;
              }
              .clear-filter {
                padding: 0.25rem;
                font-size: 1.2rem;
              }
            }
          }
        }
        h1 {
          font-size: 3rem;
        }
      }
      .no-class {
        font-size: 1.6rem;
      }
      .unit-header {
        
        .unit-title {
          font-size: 1.6rem;
        }
        .unit-header-dd {
          h1 {
            font-size: 1.6rem;
          }
          .svg-inline--fa {
            height: 3rem;
            width: 3rem;
          }
        }
        .unit-dd {
          
          .unit-details {
            
            .student-info {
              .stud-metrics {
                
                .student-conf {
                  p {
                    span {
                      font-size: 1.4rem;
                    }
                  }
                }
              }
              h1 {
                font-size: 1.6rem;
              }
              p {
                font-size: 1.4rem;
              }
              .datachart {
                .student-progress-chart, .course-progress-chart {
                  canvas {
                      width: 90%;
                  }
                  .cert-btn-divs {
                      width: 10%;
                      .cert-btn {
                          margin-top: -20%;
                          button {
                              font-size: 1.1rem;
                              padding: 0.5rem;
                          }
                      }
                  }
              }
              }
              .module-info {
                .lesson-info {
    
                }
              }
            }
          }
        }
      }
    }
   }
  
  // Large devices (desktops, 992px and below)
  @media (min-width: 992px) { 
    .dashboard {
      .dash-header {
        h2 {
          font-size: 2rem;
        }
        .filters {
          .filter {
            label {
              font-size: 1.6rem;
            }
            .filter-inputs {
              input {
                font-size: 1.4rem;
              }
              .clear-filter {
                font-size: 1.4rem;
              }
            }
          }
        }
        h1 {
          font-size: 3rem;
        }
      }
      .no-class {
        font-size: 1.6rem;
      }
      .unit-header {
        
        .unit-title {
        }
        .unit-header-dd {
          h1 {
          }
          .svg-inline--fa {
           
          }
        }
        .unit-dd {
          
          .unit-details {
            display: flex;
            flex-wrap: wrap;
            .student-info {
              width: 45%;
              margin-left: 2.5%;
              margin-right: 2.5%;
              .stud-metrics {
                
                .student-conf {
                  p {
                    span {
                      font-size: 1.6rem;
                    }
                  }
                }
              }
              h1 {
              }
              p {
                font-size: 1.4rem;
              }
              .datachart {
                .student-progress-chart, .course-progress-chart {
                  canvas {
                      width: 90%;
                  }
                  .cert-btn-divs {
                      width: 10%;
                      .cert-btn {
                          margin-top: -25%;
                          button {
                              font-size: 1.4rem;
                              padding: 0.5rem;
                          }
                      }
                  }
              }
              }
              .module-info {
                .lesson-info {
    
                }
              }
            }
            .expanded {
              width: 95%;
              margin-left: 2.5%;
              margin-right: 2.5%;
            }
          }
        }
      }
    }
   }
  
  // Extra large devices (large desktops, 1200px and below)
  @media (min-width: 1200px) { 
      .dashboard {
        .dash-header {
          h2 {
            font-size: 2.4rem;
          }
          .filters {
            .filter {
              .filter-inputs {
                input {
                  font-size: 1.6rem;
                }
                .clear-filter {
                  font-size: 1.6rem;
                }
              }
            }
          }
          h1 {
            font-size: 4rem;
          }
        }
        .no-class {
          font-size: 2rem;
        }
        .unit-header {
          
          .unit-title {
          }
          .unit-header-dd {
            h1 {
            }
            .svg-inline--fa {
            
            }
          }
          .unit-dd {
            
            .unit-details {
              .student-info {
                width: 30%;
                margin-left: 1.5%;
                margin-right: 1.5%;
                
                h1 {
                }
                .datachart {
                  .student-progress-chart {
                      
                    canvas {
                      
                    }
                  }
                }
                .module-info {
                  .lesson-info {
      
                  }
                }
              }
              .expanded {
                width: 80%;
                margin-left: 10%;
                margin-right: 10%;
              }
            }
          }
        }
      }
    }

    @media (min-width: 1600px) { 
      .dashboard {
        .unit-header {
          
          .unit-title {
          }
          .unit-header-dd {
            h1 {
            }
            .svg-inline--fa {
            
            }
          }
          .unit-dd {
            
            .unit-details {
              .student-info {
                .stud-metrics {
                
                  .student-conf {
                    p {
                      font-size: 1.6rem;
                      span {
                        font-size: 1.6rem;
                      }
                    }
                  }
                }
                h1 {
                }
                .datachart {
                  .student-progress-chart, .course-progress-chart {
                    canvas {
                        width: 90%;
                    }
                    .cert-btn-divs {
                        width: 10%;
                        .cert-btn {
                            margin-top: -15%;
                            button {
                                font-size: 1.4rem;
                                padding: 0.5rem;
                            }
                        }
                    }
                }
                }
                .module-info {
                  .lesson-info {
      
                  }
                }
              }
              .expanded {
                width: 60%;
                margin-left: 20%;
                margin-right: 20%;
              }
            }
          }
        }
      }
    }