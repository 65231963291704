@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

    #lesson-display {
        width: 90%;
        margin: 0 auto;
        margin-top: 5%;

        h1,
        h2,
        h3,
        p {
            font-family: $primaryFont;
            color: $primaryTextColor;
        }

        h1 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        #quiz-list-display {
            background-color: $secondaryBackgroundColor;

            h2 {
                text-align: center;
                padding: 1rem 0;
            }

            #quiz-list-div {
                background-color: $primarybackgroundColor;
                border-bottom: 1rem solid $secondaryBackgroundColor;
                border-left: 1rem solid $secondaryBackgroundColor;
                border-right: 1rem solid $secondaryBackgroundColor;
                padding: 1rem 0.5rem;
                #quiz-headers {
                    display: flex;
                    width: 100%;
                    background-color: $tertiaryBackgroundColor;
                    padding: 0.5rem;
                    align-items: center;

                    #quiz-name-header {
                        width: 40%;
                    }

                    #secondary-quiz-headers {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 60%;

                        #quiz-grade-header,
                        #quiz-student-comp-header {
                            width: 50%;
                            text-align: center;
                        }
                    }
                }

                #quiz-info-div {
                    width: 100%;
                    display: flex;
                    padding: 0.5rem;

                    #quiz-name {
                        width: 40%;
                    }

                    #secondary-quiz-data {
                        width: 60%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        #quiz-grade,
                        #quiz-student-comp {
                            width: 50%;
                            text-align: center;
                        }
                    }
                }

                #quiz-info-div:hover {
                    background-color: $primaryHoverColor;
                    cursor: pointer;
                }

                .quiz-line {
                    border: 1px solid #5A6770;
                }

                .back-btn-div {
                    text-align: center;

                    .back-btn {
                        background-color: $buttonColor;
                        width: 5rem;
                        padding: 0.1rem 0.5rem;
                        border-radius: 0.5rem;
                        margin: 1rem 0;

                        span {
                            color: $primaryTextColor;
                            font-family: $primaryFont;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }

}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) {

    #lesson-display {

        h1 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }

        #quiz-list-display {

            h2 {
                font-size: 1.5rem;
            }

            #quiz-list-div {
                padding: 2rem 1rem;
                #quiz-headers {

                    #quiz-name-header {
                        font-size: 1.25rem;
                    }

                    #secondary-quiz-headers {

                        #quiz-grade-header,
                        #quiz-student-comp-header {
                            font-size: 1.25rem;
                        }
                    }
                }

                #quiz-info-div {

                    #quiz-name {
                        p {
                            font-size: 1.1rem;
                        }
                    }

                    #secondary-quiz-data {

                        #quiz-grade,
                        #quiz-student-comp {
                            p {
                                font-size: 1.1rem;
                            }
                        }
                    }
                }

                .back-btn-div {
                    .back-btn {
                        span {
                            font-size: 1.1rem;
                        }
                    }
                }
            }
        }
    }

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) {

    #lesson-display {

        h1 {
            font-size: 2.75rem;
        }

        #quiz-list-display {

            h2 {
                font-size: 1.75rem;
            }

            #quiz-list-div {

                #quiz-headers {

                    #quiz-name-header {
                        font-size: 1.5rem;
                    }

                    #secondary-quiz-headers {

                        #quiz-grade-header,
                        #quiz-student-comp-header {
                            font-size: 1.5rem;
                        }
                    }
                }

                #quiz-info-div {

                    #quiz-name {
                        p {
                            font-size: 1.25rem;
                        }
                    }

                    #secondary-quiz-data {

                        #quiz-grade,
                        #quiz-student-comp {
                            p {
                                font-size: 1.25rem;
                            }
                        }
                    }
                }

                .back-btn-div {
                    .back-btn {
                        span {
                            font-size: 1.25rem;
                        }
                    }
                }
            }
        }
    }

}

// Large devices (desktops, 992px and above)
@media (min-width: 992px) {

    #lesson-display {

        h1 {
            font-size: 3.25rem;
        }

        #quiz-list-display {

            h2 {
                font-size: 2rem;
            }

            #quiz-list-div {
                border-bottom: 1.5rem solid $secondaryBackgroundColor;
                border-left: 1.5rem solid $secondaryBackgroundColor;
                border-right: 1.5rem solid $secondaryBackgroundColor;
                padding: 3rem 1.5rem;
                #quiz-headers {

                    #quiz-name-header {
                        font-size: 1.8rem;
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                        margin-left: 1rem;
                    }

                    #secondary-quiz-headers {

                        #quiz-grade-header,
                        #quiz-student-comp-header {
                            font-size: 1.8rem;
                        }
                    }
                }

                #quiz-info-div {

                    #quiz-name {
                        p {
                            font-size: 1.6rem;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                            margin-left: 1rem;
                        }
                    }

                    #secondary-quiz-data {

                        #quiz-grade,
                        #quiz-student-comp {
                            p {
                                font-size: 1.6rem;
                            }
                        }
                    }
                }

                .back-btn-div {
                    .back-btn {
                        width: 10rem;
                        padding: 0.5rem;
                        margin: 2rem 0;

                        span {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }

}

// Extra large devices (large desktops, 1200px and above)
@media (min-width: 1200px) {

    #lesson-display {

        #quiz-list-display {

            #quiz-list-div {
                padding: 4rem 2rem;
                #quiz-headers {

                    #quiz-name-header {
                        width: 60%
                    }

                    #secondary-quiz-headers {
                        margin-left: 2.2%;
                        width: 37.8%;
                    }
                }

                #quiz-info-div {

                    #quiz-name {
                        width: 60%;
                    }

                    #secondary-quiz-data {
                        margin-left: 2.2%;
                        width: 37.8%;
                    }
                }
            }
        }
    }

}