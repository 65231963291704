@import '../../index.scss';
@import '../components.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
  
  #add-useCase-div {
    width: 90%;
    margin: 1rem auto;
    h1 {
      font-size: 2rem;
      color: $primaryTextColor;
      font-family: $primaryFont;
      margin-bottom: 2rem;
    }
    form {
        .add-useCase-form-input {
          display: flex;
          flex-direction: column;
          width: 100%;
          label {
            margin: 0 0 0.5rem 0;
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1.25rem;
          }
          input {
            margin: 0 0 1rem 0;
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1.25rem;
          }
        }
      .add-useCase-form-textarea {
        display: flex;
        width: 100%;
        flex-direction: column;
        label {
          font-size: 1.25rem;
          margin: 0;
        }
        textarea {
          margin-top: 0.5rem;
          margin-bottom: 1rem;
          height: 10rem;
          font-size: 1.25rem;
          background-color: $formInputBackgroundColor;
          color: $primaryTextColor;
          font-family: $primaryFont;
          border: none;
          border-radius: .5rem;
          padding: 0.5rem;
        }
      }
      #add-useCase-btns {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 2rem;
        button {
          margin: 0 0.5rem;
          background-color: $buttonColor;
          width: 8rem;
          border-radius: 0.5rem;
          span {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1.25rem;
          }
        }
      }
    }
    .message {
      text-align: center;
    }
  }

}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) { 

  #add-useCase-div {
    margin-top: 6rem;
    h1 {
      font-size: 3rem;
      margin-bottom: 3rem;
    }
    form {
        .add-useCase-form-input {
          label {
            font-size: 1.5rem;
            width: 100%;
          }
          input {
            font-size: 1.5rem;
            width: 100%;
          }
        }
      
      .add-useCase-form-textarea {
        label {
          font-size: 1.5rem;
        }
        textarea {
          height: 10rem;
          font-size: 1.5rem;
        }
      }
      #add-useCase-btns {
        button {
          margin: 0 1rem;
          width: 10rem;
          span {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

} 

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) { 

  #add-useCase-div {
    margin-top: 8rem;
    form {
      .add-useCase-form-input {
        input {
          width: 100%;
          font-size: 1.5rem;
        }
      }
      .add-useCase-form-textarea {
        textarea {
          height: 15rem;
        }
      }
    }
      
 }

 }

// Large devices (desktops, 992px and above)
@media (min-width: 992px) { 

  #add-useCase-div {
    margin-top: 10rem;
    width: 79rem;
  }

 }

// Extra large devices (large desktops, 1200px and above)
@media (min-width: 1200px) { 

 }