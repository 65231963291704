@import '../../index.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 

    // style for displayed contact info
    #bio-div {
      margin-top: 1rem;
      margin-left: 0.5rem;
      display: flex;
      align-items: center;
      #bio-img {
        span {
          .MuiSvgIcon-root {
            color: #DADADA;
            font-size: 5rem;
          }
        }
      }
      #bio-name {
        color: $primaryTextColor;
        font-family: $primaryFont;
        margin-left: 0.5rem;
        h1 {
          font-size: 2rem;
        }
        h2 {
          font-style: italic;
          font-size: 1.5rem;
        }
      }
    }

    #contact-info {
      color: $primaryTextColor;
      font-family: $primaryFont;
      margin-left: 1rem;
      margin-top: 1.5rem;

      div h3 {
        font-size: 1rem;
      }
      div p {
        font-size: 1.25rem;
        margin-top: .5rem;
        margin-bottom: 1rem;
      }
      #edit-btn-div {
        width: 20rem;
        button {
          background: $buttonColor;
          border-radius: 0.5rem;
          width: 10rem;
          padding: 0.5rem;
          span {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1rem;
          }
        }
      }
    }
      
    // style for edit contact info
    #edit-contact-info {
      color: $primaryTextColor;
      font-family: $primaryFont;
      margin-left: 1rem;
      margin-top: 1rem;
      margin-right: 1rem;
      div label {
        font-size: 1rem;
      }
      div .contact-input {
        color: $primaryTextColor;
        font-size: 1rem;
        margin-bottom: .5rem;
        width: 20rem;
      }
      #edit-contact-btns {
        align-items: center;
        margin: 1rem 0;
        width: 30rem;
        button {
          background: $buttonColor;
          border-radius: 0.5rem;
        }
        #edit-contact-cancel, #edit-contact-save {
          width: 10rem;
          height: 2.5rem;
          padding: 0.5rem;
          span {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1rem;
          }
        }
        #edit-contact-cancel {
          margin-right: 0.375rem
        }
        #edit-contact-save {
          margin-left: 0.375rem
        }
      }
    }
  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    // style for displayed contact info
    #bio-div {
      #bio-img {
        span {
          .MuiSvgIcon-root {
            font-size: 6rem;
          }
        }
      }
      #bio-name {
        h1 {
          font-size: 3rem;
        }
        h2 {
          font-size: 2rem;
        }
      }
    }

    #contact-info {
      div h3 {
        font-size: 1.25rem;
      }
      div p {
        font-size: 1.65rem;
      }
      #edit-btn-div {
        button {
          width: 10rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }
      
    // style for edit contact info
    #edit-contact-info {
      div label {
        font-size: 1.25rem;
      }
      div .contact-input {
        font-size: 1.25rem;
      }
      #edit-contact-btns {
        #edit-contact-cancel, #edit-contact-save {
          height: 2.75rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    // style for displayed contact info
    #bio-div {
      #bio-img {
        span {
          .MuiSvgIcon-root {
            font-size: 7rem;
          }
        }
      }
    }

     // style for edit contact info
     #edit-contact-info {
      div .contact-label {
        font-size: 1.25rem;
        margin: 0rem;
      }
      div .contact-input {
        font-size: 1.25rem;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
      #edit-contact-btns {
        margin-top: 0rem;
        #edit-contact-cancel, #edit-contact-save {
          margin-top: 0rem;
          height: 2.75rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }

   }
  
  // Large devices (desktops, 992px and below)
  @media (min-width: 992px) { 

      // style for edit contact info
     #edit-contact-info {
      div .contact-label {
        font-size: 1.25rem;
        margin: 0rem;
      }
      div .contact-input {
        font-size: 1.25rem;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        margin-left: 0;
        margin-right: 0;
      }
      #edit-contact-btns {
        margin-top: 0rem;
        #edit-contact-cancel, #edit-contact-save {
          height: 2.75rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }

   }
  
  // Extra large devices (large desktops, 1200px and below)
  @media (min-width: 1200px) { 
  
    }