@import '../index.scss';

.box {
    width: 80%;
    margin: 0 auto;
  }

  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
  }

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
    
    .loading {
      color: $primaryTextColor;
      margin-bottom: 2rem;
      font-size: 3rem;
      font-family: $primaryFont;
    }

    .title {
        color: $primaryTextColor;
        margin-top: 50%;
        margin-bottom: 2rem;
        font-size: 3rem;
        font-family: $primaryFont;
      }
    
      form label, form input, .form-link-div, .line, .form-btn-div, .message-div {
        width: 100%;
      }
    
      form label {
        color: $primaryTextColor;
        font-family: $primaryFont;
        font-size: 1.5rem;
        margin: 1rem 0;
      }
    
      form input {
        background: $formInputBackgroundColor;
        border-radius: .5rem;
        font-size: 1.5rem;
        padding: .5rem;
        margin-bottom: 1rem;
        color: $primaryTextColor;
        font-family: $primaryFont;
      }
    
      form a {
        color: $primaryTextColor;
        font-family: $primaryFont;
        font-size: 1.5rem;
        text-align: right;
        margin-top: 2rem;
        margin-bottom: 3rem;
      }
    
      .line {
        border: .1rem solid $primaryTextColor;
        margin-bottom: 2rem;
      }
    
      button[type='submit'] {
        background: $buttonColor;
        border-radius: .5rem;
        width: 8rem;
        height: 3.25rem;
    
        span {
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 1.25rem;
        }
      }

      .message-div {
        justify-content: center;
      }
    
      .message {
        color: $primaryTextColor;
        font-family: $primaryFont;
        font-size: 1.5rem;
        font-style: italic;
        margin-top: 2rem;
      }
    
      #qi-logo-div {
        position: fixed;
        bottom: 4.5rem;
        left: 3.5rem;
      }
    
      #qi-logo-div img {
        width: 20rem;
      }

      .page-border {
        border-top: 2rem solid $secondaryBackgroundColor;
        border-left: 2rem solid $secondaryBackgroundColor;
        width: 100%;
      }

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) {

      .box {
        width: 80%;
        margin-left: 18%;
      }

      .title {
        margin-top: 20%;
        font-size: 4rem;
        margin-bottom: 4rem;
      }
    
      form label, form input, .form-link-div, .line, .form-btn-div, .message-div {
        width: 80%;
      }
    
      form label {
        margin-bottom: 2rem;
      }
    
      form input {
        margin-bottom: 3rem;
      }
    
      form a {
        margin-bottom: 5rem
      }
    
      .line {
        margin-bottom: 4rem;
      }
    
      button[type='submit'] {
        width: 17rem;
        height: 4rem;
    
        span {
          font-size: 1.5rem;
        }
      }
    
      .message {
        font-size: 1.5rem;
      }

   }
  
  // Large devices (desktops, 992px and below)
  @media (min-width: 992px) { 

    .box {
        width: 80%;
        margin: 0 auto;
      }
    
      .title {
        font-size: 3.75rem;
        margin: 16rem auto 4rem;
        width: 50rem;
      }
    
      form label, form input, .form-link-div, .line, .form-btn-div, .message-div {
        margin: 0 auto;
        width: 50rem
      }
    
      form label {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
    
      form input {
        font-size: 1.75rem;
        margin-bottom: 2rem;
      }
    
      form a {
        font-size: 1.5rem;
        margin-bottom: 5rem
      }
    
      .line {
        margin-bottom: 4rem;
      }
    
      button[type='submit'] {
        width: 19rem;
        height: 4rem;
    
        span {
          font-size: 1.5rem;
        }
      }
    
      .message {
        font-size: 2rem;
      }
     
   }
  
  // Extra large devices (large desktops, 1200px and below)
  @media (min-width: 1200px) { 
  
   }