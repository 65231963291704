@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
    #nav-div {
      display: flex;
      min-height: 100vh;
    }

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

   }
  
  // Large devices (desktops, 992px and below)
  @media (min-width: 992px) { 

   }
  
  // Extra large devices (large desktops, 1200px and below)
  @media (min-width: 1200px) { 
  
    }