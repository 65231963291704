@import '../../index.scss';
@import '../components.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 

  #edit-tag-div {
    width: 90%;
    margin: 1rem auto;
    h1 {
      font-size: 2rem;
      color: $primaryTextColor;
      font-family: $primaryFont;
      margin-bottom: 2rem;
    }
    form {
      .edit-tag-row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .edit-tag-form-input {
          display: flex;
          flex-direction: column;
          width: 100%;
          label {
            margin: 0 0 0.5rem 0;
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1.25rem;
          }
          input {
            margin: 0 0 1rem 0;
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1.25rem;
            margin-top: 0.5rem;
          }
        }
      }
      // style labels and input fields outside of the class 'edit-tag-row'
      .edit-tag-form-input {
        display: flex;
        flex-direction: column;
        width: 100%;
        label {
          margin: 0 0 0.5rem 0;
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 1.25rem;
        }
        input {
          margin: 0 0 1rem 0;
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 1.25rem;
        }
        p 
          {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1.25rem;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            font-size: 1.25rem;
            margin-left: 0.5rem;
          }
      }
      .dropdown-div {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        #cust-name {
          color: $primaryTextColor;
          font-family: $primaryFont;
          margin-top: 1rem;
          margin-bottom: 1rem;
          font-size: 1.25rem;
          margin-left: 0.5rem;
        }
        #edit-tag-tier
        {
          p 
          {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1.25rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-size: 1.25rem;
            margin-left: 0.5rem;
          }
        }
      }
      .edit-tag-form-textarea {
        display: flex;
        width: 100%;
        flex-direction: column;
        label {
          font-size: 1.25rem;
          margin: 0;
        }
        textarea {
          margin-top: 0.5rem;
          margin-bottom: 1rem;
          height: 5rem;
          font-size: 1.25rem;
          background-color: $formInputBackgroundColor;
          color: $primaryTextColor;
          font-family: $primaryFont;
          border: none;
          border-radius: .5rem;
          padding: 0.5rem;
        }
      }
      .edit-tag-dropdown {
        display: flex;
        flex-direction: column;
        width: 100%;
        label {
          font-size: 1.25rem;
          margin: 0;
          margin-bottom: 0.5rem;
        }
        select {
          margin-top: 0.5rem;
          margin-bottom: 1rem;
          height: 2.8rem;
          font-size: 1.25rem;
          background-color: $formInputBackgroundColor;
          color: $primaryTextColor;
          font-family: $primaryFont;
          border: none;
          border-radius: .5rem;
          padding: 0.5rem;
          option:hover {
            background-color: $primaryHoverColor;
            cursor: pointer;
          }
        }
      }
      #edit-tag-btns {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 2rem;
        button {
          margin: 0 0.5rem;
          background-color: $buttonColor;
          width: 8rem;
          border-radius: 0.5rem;
          span {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1.25rem;
          }
        }
      }
    }
    .message {
      text-align: center;
    }
  }

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    #edit-tag-div {
      margin-top: 6rem;
      h1 {
        font-size: 3rem;
        margin-bottom: 3rem;
      }
      form {
        .edit-tag-row {
          justify-content: space-between;
          .edit-tag-form-input {
            width: 45%;
            label {
              font-size: 1.5rem;
            }
            input {
              font-size: 1.5rem;
            }
          }
        }
        // edit style to label and input fields outside of edit-tag-row
        .edit-tag-form-input {
          label {
            font-size: 1.5rem;
          }
          input {
            font-size: 1.5rem;
          }
          p
          {
            font-size: 1.5rem;
          }
        }
        .dropdown-div {
          justify-content: space-between;
          #edit-tag-tier
        {
          p 
          {
            font-size: 1.5rem;
          }
        }
          #cust-name {
            font-size: 1.5rem;
          }
        }
        .edit-tag-form-textarea {
          label {
            font-size: 1.5rem;
          }
          textarea {
            height: 10rem;
            font-size: 1.5rem;
          }
        }
        .edit-tag-dropdown {
          width: 45%;
          label {
            font-size: 1.5rem;
          }
          select {
            height: 3.1rem;
            font-size: 1.5rem;
          }
        }
        #edit-tag-btns {
          button {
            margin: 0 1rem;
            width: 10rem;
            span {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    #edit-tag-div {
      margin-top: 8rem;
      form {
        .edit-tag-form-input {
          input {
            width: 100%;
          }
        }
      }
    }

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

    #edit-tag-div {
      margin-top: 10rem;
      width: 79rem;
      label {
        width: 80%;
      }
    }

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
  
   }