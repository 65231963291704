@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

   .saveAs-lesson-page {
        margin-left: 1rem;
        margin-top: 1rem;
        margin-right: 1rem;
        color: $primaryTextColor;
        font-family: $primaryFont;
        h1 {
            font-size: 2rem;
            margin-bottom: 2rem;
        }
        .lesson-metadata {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .lesson-hierarchy {
                display: flex;
                flex-direction: column;
                .lesson-title-div {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 2rem;
                    .title-toggle {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 0.5rem;
                        h2 {
                            font-size: 1.25rem;
                        }
                        .readonly-toggle {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            label {
                                font-size: 1.25rem;
                                color: black;
                            }
                            .toggle-switch {
                                width: 3.75rem;
                                height: 1.5rem;
                                margin-left: 1rem;
                                cursor: pointer;
                            }
                            .toggle-switch .switch {  
                                background-color: #aaa;
                                border-radius: 2.5rem;
                            }
                            .toggle-switch .switch::before {
                                left: 0.2rem;
                                top: 0.2rem;
                                width: 1.1rem;
                                height: 1.1rem;
                                background-color: #ffffff;
                            }
                            .toggle-switch input[type="checkbox"]:checked + .switch::before {
                                transform: translateX(2.18rem);
                                background-color: rgb(240, 240, 49);
                            }
                            .toggle-switch input[type="checkbox"]:checked + .switch {
                                background-color: #aaa;
                            }
                        }
                    }
                    input {
                        background-color: $formInputBackgroundColor;
                        font-style: italic;
                        padding: 0.5rem;
                        font-size: 1.25rem;
                        color: $primaryTextColor;
                        font-family: $primaryFont;
                    }
                }
                .lesson-media-type {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 2rem;
                    label {
                        font-size: 1.25rem;
                    }
                    p {
                        font-size: 1.25rem;
                    }
                }
                .dropdown {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 2rem;
                    label {
                        font-size: 1.25rem;
                    }
                    select {
                        margin-top: 0.5rem;
                        margin-bottom: 1rem;
                        margin-left: 0.5rem;
                        height: 2.8rem;
                        font-size: 1.5rem;
                        background-color: $formInputBackgroundColor;
                        color: $primaryTextColor;
                        font-family: $primaryFont;
                        border: none;
                        border-radius: .5rem;
                        padding: 0.5rem;
                    }
                    select[name='moduleId'] {
                        width: 25.1rem;
                    }
                }
            }
            .lesson-overview {
                display: flex;
                flex-direction: column;
                margin-bottom: 2rem;
                label {
                    margin-bottom: 0.5rem;
                    font-size: 1.25rem;
                }
                textarea {
                    font-size: 1.25rem;
                    font-style: italic;
                    background-color: $formInputBackgroundColor;
                    padding: 0.5rem;
                    width: 29.5rem;
                    height: 10rem;
                    color: $primaryTextColor;
                    font-family: $primaryFont;
                }
            }
        }
        .lesson-tags {
            display: flex;
            flex-direction: column;
            .lesson-tags-header {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                h2 {
                    font-size: 1.25rem;
                    background-color: $formInputBackgroundColor;
                    padding: 0.5rem 2rem;
                    border-radius: 20%;
                }
                .alphabet {
                    display: flex;
                    color: black;
                    font-size: 1.5rem;
                    p {
                        margin-left: 0.5rem;
                        margin-top: 0.5rem;
                        cursor: pointer;
                    }
                }
            }
            .tag-line {
                border: 0.1rem solid $formInputBackgroundColor;
                margin-top: 0.5rem;
                margin-bottom: 2rem;
            }
            .tags-added {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: center;
                h3 {
                    align-self: flex-start;
                    color: black;
                    font-family: $primaryFont;
                    font-size: 1.25rem;
                }
                .tags-added-list {
                    align-self: flex-start;
                    display: flex;
                    flex-wrap: wrap;
                    list-style: none;
                    li {
                        font-size: 1.25rem;
                        background-color: $formInputBackgroundColor;
                        padding: 0.5rem 2rem;
                        border-radius: 25%;
                        margin-right: 0.5rem;
                        margin-bottom: 0.5rem;
                        cursor: pointer;
                        span {
                            font-size: 1.25rem;
                            color: red;
                            cursor: pointer;
                        }
                    }
                }
            }
            .tags-list-div {
                display: flex;
                flex-direction: column;
                p {
                    color: black;
                    font-family: $primaryFont;
                    font-size: 1.5rem; 
                    margin-bottom: 1rem;
                }
                .tags-list {
                    align-self: flex-start;
                    display: flex;
                    flex-wrap: wrap;
                    list-style: none;
                    li {
                        font-size: 1.25rem;
                        background-color: $formInputBackgroundColor;
                        padding: 0.5rem 2rem;
                        border-radius: 25%;
                        margin-right: 0.5rem;
                        margin-bottom: 0.5rem;
                        cursor: pointer;
                    }
                }
            }
        }
        .saveAs-lesson-btns {
            display: flex;
            width: 100%;
            justify-content: center;
            margin-top: 2rem;
            margin-bottom: 2rem;
            button {
                margin: 0 0.5rem;
                background-color: $buttonColor;
                width: 8rem;
                border-radius: 0.5rem;

                span {
                    color: $primaryTextColor;
                    font-family: $primaryFont;
                    font-size: 1.25rem;
                }
            }
        }
        .progress {
            z-index: 10;
            height: 50%;
            width: 50%;
            position: absolute;
            top: 25%;
            left: 25%;
            background-color: $primarybackgroundColor;
            border: 1rem solid black;
            border-radius: 0.5rem;
            box-shadow: 0.5rem 0.5rem 15rem black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .message {
                padding: 1rem;
                margin-bottom: 1rem;
            }
            svg {
                color: $primaryHoverColor;
            }
            .progress-button {
                margin: 1rem 0.5rem;
                background-color: $buttonColor;
                width: 8rem;
                border-radius: 0.5rem;
                color: $primaryTextColor;
                font-family: $primaryFont;
                font-size: 1.25rem;
            }
        }
   }

}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) {

    .progress {
        .message {
            font-size: 2rem;
        }
    }

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) {

    .saveAs-lesson-page {
        h1 {
            font-size: 3rem;
        }
        .lesson-metadata {
            flex-wrap: unset;
            .lesson-hierarchy {
                .lesson-title-div {
                    .title-toggle {
                        h2 {
                            font-size: 1.5rem;
                        }
                        .readonly-toggle {
                            label {
                                font-size: 1.5rem;
                            }
                        }
                    }
                    input {
                        font-size: 1.5rem;
                    }
                }
                .lesson-media-type {
                    label {
                        font-size: 1.5rem;
                    }
                    p {
                        font-size: 1.5rem;
                    }
                }
                .dropdown {
                    label {
                        font-size: 1.5rem;
                    }
                    select {
                        height: 3.1rem;
                        font-size: 1.5rem;
                    }
                }
            }
            .lesson-overview {
                label {
                    font-size: 1.5rem;
                }
                textarea {
                    font-size: 1.5rem;
                    height: 100%;
                }
            }
        }
        .lesson-tags {
            .lesson-tags-header {
                h2 {
                    font-size: 1.5rem;
                }
                .alphabet {
                    font-size: 2rem;
                }
            }
            .tags-added {
                h3 {
                    font-size: 1.5rem;
                }
                .tags-added-list {
                    li {
                        font-size: 1.5rem;
                        span {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            .tags-list-div {
                p {
                    font-size: 1.75rem; 
                }
                .tags-list {
                    li {
                        font-size: 1.5rem;
                    }
                }
            }
        }
        .saveAs-lesson-btns {
            button {
                margin: 0 1rem;
                width: 10rem;
                span {
                  font-size: 1.5rem;
                }
            }
        }
        .progress {
            .progress-button {
                width: 10rem;
                font-size: 1.5rem;
                padding: 1rem 0;
            }
        }
   }

}

// Large devices (desktops, 992px and above)
@media (min-width: 992px) {

    .saveAs-lesson-page {
        width: 85rem;
        h1 {
            font-size: 3.5rem;
        }
        .lesson-metadata {
            .lesson-hierarchy {
                width: 48%;
                .lesson-title-div {
                    .title-toggle {
                        h2 {
                            font-size: 1.75rem;
                        }
                        .readonly-toggle {
                            label {
                                font-size: 1.75rem;
                            }
                        }
                    }
                    input {
                        font-size: 1.75rem;
                    }
                }
                .lesson-media-type {
                    label {
                        font-size: 1.75rem;
                    }
                    p {
                        font-size: 1.75rem;
                    }
                }
                .dropdown {
                    label {
                        font-size: 1.75rem;
                    }
                    select {
                        height: 3.5rem;
                        font-size: 1.75rem;
                    }
                    select[name='moduleId'] {
                        width: 28.8rem;
                    }
                }
            }
            .lesson-overview {
                width: 48%;
                label {
                    font-size: 1.75rem;
                }
                textarea {
                    font-size: 1.75rem;
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .lesson-tags {
            .lesson-tags-header {
                h2 {
                    font-size: 1.75rem;
                }
                .alphabet {
                    font-size: 2.5rem;
                }
            }
            .tags-added {
                h3 {
                    font-size: 1.75rem;
                }
            }
            .tags-list-div {
                p {
                    font-size: 2.5rem; 
                }
            }
        }
        .progress {
            z-index: 10;
            height: 43.25rem;
            width: 50.35rem;
            position: absolute;
            top: 22rem;
            left: 30rem;
        }
   }

}

// Extra large devices (large desktops, 1200px and above)
@media (min-width: 1200px) {

    
    

}