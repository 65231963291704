@import '../../index.scss';
@import '../components.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
  
    #add-cust-div {
      width: 90%;
      margin: 1rem auto;
      h1 {
        font-size: 2rem;
        color: $primaryTextColor;
        font-family: $primaryFont;
        margin-bottom: 2rem;
      }
      form {
        h2 {
          font-size: 1.5rem;
          color: $primaryTextColor;
          font-family: $primaryFont;
          margin-bottom: 1.5rem;
        }
        .add-cust-row {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .add-cust-form-input {
            display: flex;
            flex-direction: column;
            width: 100%;
            label {
              margin: 0 0 0.5rem 0;
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-size: 1.25rem;
            }
            input {
              margin: 0 0 1rem 0;
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-size: 1.25rem;
            }
          }
        }
        #add-cust-location {
          display: flex;
          width: 100%;
          flex-direction: column;
          label {
            font-size: 1.25rem;
            margin: 0;
          }
          select {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            height: 2.8rem;
            font-size: 1.5rem;
            background-color: $formInputBackgroundColor;
            color: $primaryTextColor;
            font-family: $primaryFont;
            border: none;
            border-radius: .5rem;
            padding: 0.5rem;
          }
        }
        #add-cust-btns {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-top: 2rem;
          button {
            margin: 0 0.5rem;
            background-color: $buttonColor;
            width: 8rem;
            border-radius: 0.5rem;
            span {
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-size: 1.25rem;
            }
          }
        }
      }
      .message {
        text-align: center;
      }
    }

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    #add-cust-div {
      margin-top: 6rem;
      h1 {
        font-size: 3rem;
        margin-bottom: 3rem;
      }
      form {
        h2 {
          font-size: 2rem;
          margin-bottom: 2rem;
        }
        .add-cust-row {
          justify-content: space-between;
          .add-cust-form-input {
            width: 45%;
            label {
              font-size: 1.5rem;
            }
            input {
              font-size: 1.5rem;
            }
          }
        }
        #add-cust-location {
          label {
            font-size: 1.5rem;
          }
          select {
            height: 3.1rem;
            font-size: 1.5rem;
          }
        }
        #add-cust-btns {
          button {
            margin: 0 1rem;
            width: 10rem;
            span {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

  } 
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    #add-cust-div {
      margin-top: 8rem;
      .add-cust-row {
        justify-content: space-between;
        .add-cust-form-input {
          input {
            width: 100%;
          }
        }
      }
    }

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

    #add-cust-div {
      margin-top: 10rem;
      width: 79rem;
      label {
        width: 80%;
      }
    }
 
   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
  
   }