@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape descs, 0 - 575px)
@media (min-width: 0px) { 

  #useCases-div {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
    color: $primaryTextColor;
    font-family: $primaryFont;
    div h1 {
      font-size: 1.5rem;
    }
    #search-text {
      background-color: $formInputBackgroundColor;
      color: $primaryTextColor;
      font-family: $primaryFont;
      padding: 0.5rem;
      font-size: 1rem;
      margin-right: 1rem;
    }
    #add-useCase-btn {
      color: $primaryTextColor;
      background-color: $buttonColor;
      padding: .2rem 0;
      width: 10rem;
      border-radius: 0.5rem;
      span {
        font-size: 1rem;
      }
    }
    #useCases-titles {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: $tertiaryBackgroundColor;
      margin-top: 1rem;
      padding: 0.5rem;
      #useCase-name-header {
        width: 60%;
      }
      #secondary-useCase-headers {
        justify-content: space-around;
        align-items: center;
        width: 40%;
        #useCase-notes-header {
          display: none;
        }
        #useCase-desc-header {
          display: none;
        }
      }
    }
    #useCase-info-div {
      display: flex;
      justify-content: space-between;
      margin: 0.5rem;
      #useCase-name {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        width: 60%;
        #useCase-email {
          font-style: italic;
        }
      }
      #secondary-useCase-data {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 40%;
        #useCase-notes {
          display: none;
        }
        #useCase-desc {
          display: none;
        }
        #useCase-more {
          .edit-btn {
            background-color: $buttonColor;
            width: 3rem;
            padding: 0.1rem 0.5rem;
            border-radius: 0.5rem;
            span {
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-size: 1rem;
            }
          }
        }
      }
    }
    
    .useCase-line {
      border: 1px solid #5A6770;
    }
    #show-more-div {
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      #show-more-btn, #show-less-btn  {
        background-color: $buttonColor;
        width: 7rem;
        padding: 0.5rem 0.5rem;
        border-radius: 0.5rem;
        span {
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 1rem;
        }
      }
      #show-more-btn {
        margin-right: 0.5rem;
      }
      #show-less-btn {
        margin-left: 0.5rem;
      }
    }
  } 

  }
  
  // Small devices (landscape descs, 576 - 767px)
  @media (min-width: 576px) { 

    #useCases-div {
      div h1 {
        font-size: 3rem;
      }
      #search-text {
        font-size: 1.25rem;
        width: 21rem;
      }
      #add-useCase-btn {
        height: 3rem;
        width: 13rem;
        span {
          font-size: 1.25rem;
        }
      }
      #useCases-titles {
        padding: 1rem;
        h2 {
          font-size: 1.25rem;
        }
        #useCase-name-header {
          width: 40%;
        }
        #secondary-useCase-headers {
          width: 60%;
          #useCase-desc-header {
            display: block;
            width: 33.333%;
            text-align: center;
          }
          #useCase-notes-header {
            display: block;
            width: 33.333%;
            text-align: center;
          }
          #useCase-more-header {
            width: 33.333%;
            text-align: center;
          }
        }
      }
      #useCase-info-div {
        margin-left: 1rem;
        margin-right: 1rem;
        p {
          font-size: 1.25rem;
        }
        #useCase-name {
          margin-top: 1rem;
          margin-bottom: 0.5rem;
          width: 40%;
        }
        #secondary-useCase-data {
          display: flex;
          align-items: center;
          width: 60%;
          #useCase-desc {
            width: 33.333%;
            display: block;
            text-align: center;
          }
          #useCase-notes {
            width: 33.333%;
            display: block;
            text-align: center;
          }
          #useCase-more {
            width: 33.333%;
            text-align: center;
            .edit-btn {
              width: 5rem;
              span {
                font-size: 1.25rem;
              }
            }
          }
        }
      }
      .useCase-line {
        border: 1px solid #5A6770;
      }
      #show-more-div {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        #show-more-btn, #show-less-btn  {
          width: 9rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
  
   }