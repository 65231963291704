@import '../../index.scss';
@import '../pages.scss';

  // Small devices (landscape phones, 0 - 575px)
  @media (min-width: 0px) { 

    #forgotPw-title {
      margin-top: 60%;
    }

    #forgetPw-btn {
      margin-top: 2rem;
      width: 14rem
    }
  
  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 
  
    #forgotPw-title {
      margin-top: 40%;
      margin-bottom: 3rem;
    }

    #forgetPw-btn {
      margin-top: 1.5rem;
      width: 16rem;
    }

   }
  
  // Large devices (desktops, 992px and below)
  @media (min-width: 992px) { 

    #forgotPw-title {
      margin: 28rem auto 4rem;
    }

   }
  
  // Extra large devices (large desktops, 1200px and below)
  @media (min-width: 1200px) { 
  
   }