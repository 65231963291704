@import '../../index.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
    
    header {
        display: flex;
        justify-content: space-between;
        margin: 1rem;
        align-items: center;
    }

    header img {
        width: 15rem;
    }

    #account-info {
        display: flex;
        align-items: center;
    }

    #account-name {
        color: $primaryTextColor;
        font-family: $primaryFont;
        align-self: flex-end;

        h1 {
            font-size: 1.2rem;
        }
        .superUser-dropdowns {
            display: flex;
            flex-direction: column;
            h2 {
                font-style: italic;
                font-size: 1.2rem;
            }
            select {
                background-color: $formInputBackgroundColor;
                color: $primaryTextColor;
                font-family: $primaryFont;
                border: none;
                border-radius: .5rem;
                padding: 0.5rem;
                margin-top: 0.1rem;
            }
        }
        
    }

    #avatar {
        background: $primaryIconColor;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        margin-left: 1rem;
        position: relative;
        #avatar-head {
            background-color: $primarybackgroundColor;
            border-radius: 50%;
            height: 1.25rem;
            width: 1.25rem;
            position: relative;
            top: 20%;
            left: 35%;
        }

        #avatar-body {
            background-color: $primarybackgroundColor;
            border-radius: 100% 100% 100% 100%;
            height: 1rem;
            width: 2.5rem;
            position: relative;
            top: 28%;
            left: 18.9%;
        }

        #avatar-dd {
            display: none; /* Hide the dropdown by default */
            position: absolute; /* Position the dropdown relative to the avatar */
            top: 100%; /* Position the dropdown below the avatar */
            left: 0;
            background-color: white;
            border: 1px solid #ccc;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            z-index: 1; /* Ensure the dropdown appears above other content */
            width: 100%;
            padding: 0.25rem;
            p {
                font-size: 1.2rem;
            }
          }
    }

    #avatar:hover #avatar-dd {
        display: block; /* Show the dropdown when hovering over the avatar */
        cursor: pointer;
      }

      #avatar-dd {
        p:hover {
            background-color: $primaryHoverColor;
            color: white;
        }
      }


}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) { 

    header img {
        width: 20rem;
    }

    #account-name {

        h1 {
            font-size: 1.5rem;
        }
        
        h2 {
            font-size: 1.5rem;
        }
        select {
            font-size: 1.25rem;
        }
    }

    #avatar {
        width: 5rem;
        height: 5rem;

        #avatar-head {
            height: 1.5rem;
            width: 1.5rem;
            top: 20%;
            left: 35%;
        }

        #avatar-body {
            border-radius: 100% 100% 100% 100%;
            height: 1.2rem;
            width: 2.8rem;
            top: 26%;
            left: 22%;
        }

        #avatar-dd {
            p {
                font-size: 1.4rem;
            }
          }
    }

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) { 

    header img {
        width: 26rem;
    }

    #account-name {

        h1 {
            font-size: 2rem;
        }
        
        h2 {
            font-size: 2rem;
        }
        select {
            font-size: 1.5rem;
        }
    }

    #avatar {
        width: 6.5rem;
        height: 6.5rem;

        #avatar-head {
            height: 2rem;
            width: 2rem;
            top: 20%;
            left: 34%;
        }

        #avatar-body {
            border-radius: 100% 100% 100% 100%;
            height: 1.5rem;
            width: 3.5rem;
            top: 26%;
            left: 23%;
        }
    }

 }

// Large devices (desktops, 992px and below)
@media (min-width: 992px) { 
    #avatar {
        #avatar-dd {
            p {
                font-size: 1.6rem;
            }
          }
    }
 }

// Extra large devices (large desktops, 1200px and below)
@media (min-width: 1200px) { 

 }