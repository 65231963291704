@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

  #customers-div {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
    color: $primaryTextColor;
    font-family: $primaryFont;
    div h1 {
      font-size: 1.5rem;
    }
    #search-text {
      background-color: $formInputBackgroundColor;
      color: $primaryTextColor;
      font-family: $primaryFont;
      padding: 0.5rem;
      font-size: 1rem;
      margin-right: 1rem;
    }
    #add-customer-btn {
      color: $primaryTextColor;
      background-color: $buttonColor;
      padding: .2rem 0;
      width: 10rem;
      border-radius: 0.5rem;
      span {
        font-size: 1rem;
      }
    }
    #customers-titles {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: $tertiaryBackgroundColor;
      margin-top: 1rem;
      padding: 0.5rem;
      #customer-location-header {
        display: none;
      }
      #customer-phone-header {
        display: none;
      }
      #secondary-customer-headers {
        justify-content: flex-end;
        align-items: center;
        #customer-more-header {
          margin-right: 0.5rem;
        }
      }
    }
    #customer-info-div {
      display: flex;
      justify-content: space-between;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      #customer-name {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        #customer-email {
          font-style: italic;
        }
      }
      #secondary-customer-data {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      #customer-location {
        display: none;
      }
      #customer-phone {
        display: none;
      }
      #customer-more {
        .edit-btn {
          background-color: $buttonColor;
          width: 3rem;
          padding: 0.1rem 0.5rem;
          border-radius: 0.5rem;
          span {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1rem;
          }
        }
      }
    }
    
    .customer-line {
      border: 1px solid #5A6770;
    }
    #show-more-div {
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      #show-more-btn, #show-less-btn  {
        background-color: $buttonColor;
        width: 7rem;
        padding: 0.5rem 0.5rem;
        border-radius: 0.5rem;
        span {
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 1rem;
        }
      }
      #show-more-btn {
        margin-right: 0.5rem;
      }
      #show-less-btn {
        margin-left: 0.5rem;
      }
    }
  } 

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    #customers-div {
      div h1 {
        font-size: 3rem;
      }
      #search-text {
        font-size: 1.25rem;
        width: 21rem;
      }
      #add-customer-btn {
        height: 3rem;
        width: 13rem;
        span {
          font-size: 1.25rem;
        }
      }
      #customers-titles {
        padding: 1rem;
        h2 {
          font-size: 1.25rem;
        }
        #customer-name-header {
          width: 50%;
        }
        #secondary-customer-headers {
          width: 50%;
          display: flex;
          justify-content: space-around;
          #customer-phone-header {
            display: block;
            width: 25%;
            text-align: center;
          }
          #customer-location-header {
            display: block;
            width: 25%;
            text-align: center;
          }
          #customer-more-header {
            margin: 0;
            width: 25%;
            text-align: center;
          }
        }
      }
      #customer-info-div {
        margin-left: 1rem;
        margin-right: 1rem;
        p {
          font-size: 1.25rem;
        }
        #customer-name {
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        }
        #secondary-customer-data {
          display: flex;
          align-items: center;
          width: 50%;
          #customer-phone {
            width: 33.333%;
            display: block;
            text-align: center;
          }
          #customer-location {
            width: 33.333%;
            display: block;
            text-align: center;
          }
          #customer-more {
            width: 33.333%;
            text-align: center;
            .edit-btn {
              width: 5rem;
              span {
                font-size: 1.25rem;
              }
            }
          }
        }
      }
      .customer-line {
        border: 1px solid #5A6770;
      }
      #show-more-div {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        #show-more-btn, #show-less-btn  {
          width: 9rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 



   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
  
   }