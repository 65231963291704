@import '../../index.scss';
@import '../pages.scss';

 // Small devices (landscape phones, 576 - 767px)
 @media (min-width: 576px) { 

    #tags-div {
      div h1 {
        font-size: 3rem;
      }
      #tag-action {
        #search-text {
          font-size: 1.25rem;
          width: 21rem;
        }
        #add-tag-btn {
          height: 3rem;
          width: 13rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
      #tags-titles {
        padding: 1rem;
        h2 {
          font-size: 1.25rem;
        }
        #tag-name-header {
          width: 20%;
        }
        #secondary-tag-headers {
          width: 80%;
          #tag-cust-header {
            display: none;
          }
          #tag-useCase-header {
            display: none;
          }
          #tag-type-header {
            width: 20%;
            text-align: center;
          }
          #tag-sub1-header {
            width: 20%;
            text-align: center;
          }
          #tag-sub2-header {
            width: 20%;
            text-align: center;
          }
          #tag-desc-header {
            display: block;
            width: 20%;
            text-align: center;
          }
          #tag-more-header {
            width: 20%;
            text-align: center;
          }
        }
      }
      #tag-info-div {
        margin-left: 1rem;
        margin-right: 1rem;
        p {
          font-size: 1.25rem;
        }
        #tag-name {
          width: 20%;
        }
        #secondary-tag-data {
          width: 80%;
          #tag-cust {
            display: none;
          }
          #tag-useCase {
            display: none;
          }
          #tag-type {
            width: 20%;
            text-align: center;
          }
          #tag-sub1 {
            width: 20%;
            text-align: center;
          }
          #tag-sub2 {
            width: 20%;
            text-align: center;
          }
          #tag-desc {
            display: block;
            width: 20%;
            text-align: center;
          }
          #tag-more {
            display: block;
            width: 20%;
            text-align: center;
            .edit-btn {
              width: 5rem;
              span {
                font-size: 1.25rem;
              }
            }
          }
        }
      }
      #show-more-div {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        #show-more-btn, #show-less-btn  {
          width: 9rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    } 

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    #tags-div {
      #add-tag-btn {
        span {
          font-size: 1.5rem;
        }
      }
      #tags-titles {
        h2 {
          font-size: 1.5rem;
        }
      }
      #tag-info-div {
        p {
          font-size: 1.5rem;
        }
        #secondary-tag-data {
          #tag-more {
            .edit-btn {
              width: 6rem;
              span {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 

    #tags-div {
      #tags-titles {
        #tag-name-header {
          width: 20%;
        }
        #secondary-tag-headers {
          width: 80%;
          #tag-cust-header {
            display: block;
            width: 16.667%;
            text-align: center;
          }
          #tag-type-header {
            width: 16.667%;
          }
          #tag-sub1-header {
            width: 16.667%;
          }
          #tag-sub2-header {
            width: 16.667%;
          }
          #tag-desc-header {
            width: 16.667%;
          }
          #tag-more-header {
            width: 16.667%;
          }
        }
      }
      #tag-info-div {
        #tag-name {
          width: 20%;
        }
        #secondary-tag-data {
          width: 80%;
          #tag-cust {
            display: block;
            width: 16.667%;
            text-align: center;
          }
          #tag-type {
            width: 16.667%;
          }
          #tag-sub1 {
            width: 16.667%;
          }
          #tag-sub2 {
            width: 16.667%;
          }
          #tag-desc {
            width: 16.667%;
          }
          #tag-more {
            width: 16.667%;
          }
        }
      }
    }

   }