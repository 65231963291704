@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
    section {
        background: $secondaryBackgroundColor;
        margin: 10% 10% 0 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 2rem 4rem;
        height: 30rem;

        div img {
           width: 100%;
        }

        h1, #qivx-info {
            color: $primaryTextColor;
            font-family: $primaryFont;
        }

        h1 {
            font-size: 2.20rem;
        }

        #qivx-info {
            text-align: center;
            font-style: italic;
            font-size: 1.5rem;
            font-family: $primaryFont;
        }

        #login-page-btn {
            background: $buttonColor;
            border-radius: .5rem;
            width: 8rem;
            padding: .25rem;

            span {
                color: $primaryTextColor;
                font-family: $primaryFont;
                font-size: 1rem;
            }
        }
    }
}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) { 

    section {
        height: 40rem;

        h1 {
            font-size: 3rem;
        }

        #qivx-info {
            font-size: 1.5rem;
        }

        #login-page-btn {
            width: 13rem;
            padding: .5rem;

            span {
                font-size: 1.5rem;
            }
        }
    }

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) { 

 }

// Large devices (desktops, 992px and below)
@media (min-width: 992px) { 

    section {
        width: 80rem;
        margin: 10% auto;    
    }

 }

// Extra large devices (large desktops, 1200px and below)
@media (min-width: 1200px) { 

 }