@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

  #parent-lessons-div {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
    color: $primaryTextColor;
    font-family: $primaryFont;
    div h1 {
      font-size: 1.5rem;
    }
    #parent-lesson-action {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      #search-text, #search-tag-text {
        background-color: $formInputBackgroundColor;
        color: $primaryTextColor;
        font-family: $primaryFont;
        padding: 0.5rem;
        font-size: 1rem;
        margin-right: 1rem;
        width: 20rem;
      }
      #search-tag-text {
        display: none;
      }
    }
    #parent-lessons-titles {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: $tertiaryBackgroundColor;
      margin-top: 1rem;
      padding: 0.5rem;
      #parent-lesson-name-header {
        width: 35%;
      }
      #secondary-parent-lesson-headers {
        justify-content: space-around;
        align-items: center;
        width: 65%;
        margin-right: 0.25rem;
        #parent-lesson-author-header {
          width: 50%;
          text-align: center;
        }
        #parent-lesson-email-header {
          width: 50%;
          text-align: center;
        }
        #parent-lesson-phone-header {
            display: none;
          }
          #parent-lesson-createdDate-header {
            display: none;
          }
        #parent-lesson-modifiedDate-header {
          display: none;
        }
      }
    }
    #parent-lesson-info-div {
      display: flex;
      justify-content: space-between;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      #parent-lesson-name {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        width: 35%;
      }
      #secondary-parent-lesson-data {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 65%;
        #parent-lesson-author {
          width: 50%;
          text-align: center;
        }
        #parent-lesson-email {
          width: 50%;
          text-align: center;
          text-decoration: none;
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 1.25rem;
        }
        #parent-lesson-phone {
            display: none;
          }
        #parent-lesson-created {
            display: none;
          }
        #parent-lesson-modified {
          display: none;
        }
      }
    }
    .select-div:hover {
      background-color: $primaryHoverColor;
      cursor: pointer;
    }
    
    .parent-lesson-line {
      border: 1px solid #5A6770;
    }
    .back-btn-div {
        text-align: center;

        .back-btn {
            background-color: $buttonColor;
            width: 5rem;
            padding: 0.1rem 0.5rem;
            border-radius: 0.5rem;
            margin: 1rem 0;

            span {
                color: $primaryTextColor;
                font-family: $primaryFont;
                font-size: 1rem;
            }
        }
    }
  } 

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    #parent-lessons-div {
      div h1 {
        font-size: 3rem;
      }
      #parent-lesson-action {
        #search-text, #search-tag-text {
          font-size: 1.25rem;
          width: 25rem;
        }
        #add-parent-lesson-btn {
          height: 3rem;
          width: 13rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
      #parent-lessons-titles {
        padding: 1rem;
        h2 {
          font-size: 1.25rem;
        }
        #parent-lesson-name-header {
          width: 20%;
        }
        #secondary-parent-lesson-headers {
          width: 80%;
          margin: 0;
          #parent-lesson-author-header {
            width: 50%;
            text-align: center;
          }
          #parent-lesson-email-header {
            display: block;
            width: 50%;
          }
        }
      }
      #parent-lesson-info-div {
        margin-left: 1rem;
        margin-right: 1rem;
        p {
          font-size: 1.25rem;
        }
        #parent-lesson-name {
          margin-top: 1rem;
          margin-bottom: 0.5rem;
          width: 20%;
        }
        #secondary-parent-lesson-data {
          display: flex;
          align-items: center;
          width: 80%;
          #parent-lesson-author {
            width: 50%;
          }
          #parent-lesson-email {
            display: block;
            width: 50%;
          }
        }
      }
      .back-btn-div {
        .back-btn {
            span {
                font-size: 1.1rem;
            }
        }
     }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    #parent-lessons-div {
      #add-parent-lesson-btn {
        span {
          font-size: 1.5rem;
        }
      }
      #parent-lessons-titles {
        h2 {
          font-size: 1.5rem;
        }
          #secondary-parent-lesson-headers {
            #parent-lesson-author-header {
              width: 33.333%;
              text-align: center;
            }
            #parent-lesson-email-header {
              display: block;
              width: 33.333%;
              text-align: center;
            }
            #parent-lesson-phone-header {
                display: block;
                width: 33.333%;
                text-align: center;
              }
          }
      }
      #parent-lesson-info-div {
        p {
          font-size: 1.5rem;
        }
          #secondary-parent-lesson-data {
            #parent-lesson-author {
              width: 33.333%;
            }
            #parent-lesson-email {
              display: block;
              width: 33.333%;
            }
            #parent-lesson-phone {
                display: block;
                width: 33.333%;
                text-align: center;
            }
            #parent-lesson-phone, #parent-lesson-email {
                text-decoration: none;
                color: $primaryTextColor;
                font-family: $primaryFont;
                font-size: 1.5rem;
            }
          }
      }
      .back-btn-div {
        .back-btn {
            span {
                font-size: 1.25rem;
            }
        }
    }
    }

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

    #parent-lessons-div {
      #parent-lesson-action {
        #search-tag-text {
          display: block;
        }
      }
      #parent-lessons-titles {
        #parent-lesson-name-header {
          width: 20%;
        }
        #secondary-parent-lesson-headers {
          width: 80%;
          #parent-lesson-author-header {
            width: 25%;
          }
          #parent-lesson-email-header {
            width: 25%;
          }
          #parent-lesson-phone-header {
            width: 25%;
          }  
          #parent-lesson-createdDate-header {
            width: 25%;
            display: block;
            text-align: center;
          }
        }
      }
      #parent-lesson-info-div {
        #parent-lesson-name {
          width: 20%;
          align-self: center;
        }
        #secondary-parent-lesson-data {
          width: 80%;
          padding: 1rem 0;
          #parent-lesson-author {
            width: 25%;
          }
          #parent-lesson-email {
            width: 25%;
          }
          #parent-lesson-phone {
            width: 25%;
          }
          #parent-lesson-created {
            width: 25%;
            display: block;
            text-align: center;
          }
        }
      }
      .back-btn-div {
        .back-btn {
            width: 10rem;
            padding: 0.5rem;
            margin: 2rem 0;

            span {
                font-size: 1.6rem;
            }
        }
    }
    }

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
    
    #parent-lessons-div {
        #parent-lesson-action {
          #search-tag-text {
            display: block;
          }
        }
        #parent-lessons-titles {
          #parent-lesson-name-header {
            width: 20%;
          }
          #secondary-parent-lesson-headers {
            width: 80%;
            #parent-lesson-author-header {
              width: 20%;
            }
            #parent-lesson-email-header {
              width: 20%;
            }
            #parent-lesson-phone-header {
              width: 20%;
            }  
            #parent-lesson-createdDate-header {
              width: 20%;
            }
            #parent-lesson-modifiedDate-header {
                width: 20%;
                display: block;
                text-align: center;
              }
          }
        }
        #parent-lesson-info-div {
          #parent-lesson-name {
            width: 20%;
            align-self: center;
          }
          #secondary-parent-lesson-data {
            width: 80%;
            padding: 1rem 0;
            #parent-lesson-author {
              width: 20%;
            }
            #parent-lesson-email {
              width: 20%;
            }
            #parent-lesson-phone {
              width: 20%;
            }
            #parent-lesson-created {
              width: 20%;
            }
            #parent-lesson-modified {
                width: 20%;
                display: block;
                text-align: center;
              }
          }
        }
      }

   }