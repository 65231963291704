@import '../../index.scss';
@import '../components.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
  
    #update-pw {
      margin-top: 2rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
      color: $primaryTextColor;
      font-family: $primaryFont;
      h2 {
        font-size: 1.5rem;
      }
      form {
        color: $primaryTextColor;
        font-family: $primaryFont;
        margin-top: 1rem;
        div .update-pw-label {
          font-size: 1rem;
        }
        div .update-pw-input {
          color: $primaryTextColor;
          font-size: 1rem;
          margin-bottom: .5rem;
          width: 20rem;
        }
        #update-pw-btn-div {
          width: 20rem;
          button {
          background: $buttonColor;
          border-radius: 0.5rem;
          margin-top: 1rem;
          width: 10rem;
          height: 2.5rem;
          align-self: center;
            span {
            color: $primaryTextColor;
            font-family: $primaryFont;
            font-size: 1rem;
            }
          }
        }
      }
    }
    
  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) {

    #update-pw {
      h2 {
        font-size: 1.75rem;
      }
      form {
        div .update-pw-label {
          font-size: 1.25rem;
        }
        div .update-pw-input {
          font-size: 1.25rem;
        }
        #update-pw-btn-div {
          button {
          height: 2.75rem;
            span {
            font-size: 1.25rem;
            }
          }
        }
      }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    #update-pw {
      h2 {
        font-size: 2rem;
      }
      form {
        div .update-pw-label {
          font-size: 1.25rem;
          margin: 0rem;
        }
        div .update-pw-input {
          font-size: 1.25rem;
          margin-top: 1rem;
          margin-bottom: 1.5rem;
        }
        #message, #error-message {
          margin-bottom: 1rem;
          font-size: 2rem;
        }
        #update-pw-btn-div {
          button {
            margin-top: 0rem;
            height: 2.75rem;
            span {
            font-size: 1.25rem;
            }
          }
        }
      }
    }

   }
  
  // Large devices (desktops, 992px and below)
  @media (min-width: 992px) { 

    #update-pw {
      form {
        div .update-pw-label {
          font-size: 1.25rem;
          margin: 0rem;
        }
        div .update-pw-input {
          font-size: 1.25rem;
          margin-top: 1rem;
          margin-bottom: 1.5rem;
          margin-left: 0rem;
          margin-right: 0rem;
        }
        #update-pw-btn-div {
          button {
            margin-top: 0rem;
            height: 2.75rem;
            span {
            font-size: 1.25rem;
            }
          }
        }
      }
    }

   }
  
  // Extra large devices (large desktops, 1200px and below)
  @media (min-width: 1200px) { 
  
   }

