// variables for repo

// body
$primarybackgroundColor: #3F4345;
$secondaryBackgroundColor: #333637;
$tertiaryBackgroundColor: #595E5E;

// forms
$formInputBackgroundColor: #333637;

// text
$primaryTextColor: #FFFFFF;
$primaryFont: Arial;

// buttons
$buttonColor: #546F82;
$cancelButtonColor: #EC1626; 
$submitButtonColor: #2197D6;

// icons
$primaryIconColor: #DADADA;

// published icon backgrounds
$publishedBackgroundColor: #2197D6;
$unpublishedBackgroundColor: #EC1626;

// hover highlight
$primaryHoverColor: #5250AA;

// modals
$modalBackgroundColor: #2A2C2D;

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-size: 62.5%;
  }
  
  body, #root {
    min-height: 100vh;
    background: $primarybackgroundColor;
  }

  .flex-dir-col {
    flex-direction: column;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-right {
    justify-content: right;
  }

  .justify-left {
    justify-content: left;
  }

  .justify-space-btw {
    justify-content: space-between;
  }
  
  .justify-space-around {
    justify-content: space-around;
  }

  .justify-space-evenly {
    justify-content: space-evenly;
  }

  button {
    cursor: pointer;
  }
  
// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 

  .loading-animated {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    width: 55rem;
    height: 50rem;
    color: white;
    h1 {
      font-size: 2rem;
      font-family: $primaryFont;
      color: $primaryTextColor;
    }
    svg {
      height: 10rem;
      width: 10rem;
      animation: rotate360 2s linear infinite;
    }
  }

  @keyframes rotate360 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .MuiTooltip-popper {
    background-color: #2A2C2D;
    padding: 0;
    border: 0;
    margin: 0;
    border-radius: 1.5rem;
    box-shadow: 0.5rem 0.5rem 1rem 0.1rem rgba(0, 0, 0, 0.5);
    .MuiTooltip-tooltip {
      background-color: #2A2C2D;
    }
    .MuiTooltip, .MuiTooltip-tooltipPlacementLeft {
      background-color: #2A2C2D;
      padding: 0;
      border: 0;
      margin: 0;
      border-radius: 1.5rem;
    }
    p {
      background-color: #2A2C2D;
      border-radius: 1.5rem;
      padding: 2rem;
      #tooltip-title {
        font-size: 1.5rem;
        border-radius: 1.5rem;
      }
      #tooltip-desc {
        font-size: 1.25rem;
        font-style: italic;
        border-radius: 1.5rem;
      }
    }
  }

  .col-1 {
    width: 100%
  }

  .col-2 {
    width: 100%
  }

  .col-3 {
    width: 100%
  }

  .col-4 {
    width: 100%
  }

  .col-5 {
    width: 100%
  }

  .col-6 {
    width: 100%
  }

  .col-7 {
    width: 100%
  }

  .col-8 {
    width: 100%
  }

  .col-9 {
    width: 100%
  }

  .col-10 {
    width: 100%
  }

}

 // Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) { 

}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) { 

  .loading-animated {
    h1 {
      font-size: 3rem;
    }
    svg {
      height: 15rem;
      width: 15rem;
    }
  }

  .col-1 {
    width: 10%
  }

  .col-2 {
    width: 20%
  }

  .col-3 {
    width: 30%
  }

  .col-4 {
    width: 40%
  }

  .col-5 {
    width: 50%
  }

  .col-6 {
    width: 60%
  }

  .col-7 {
    width: 70%
  }

  .col-8 {
    width: 80%
  }

  .col-9 {
    width: 90%
  }

  .col-10 {
    width: 100%
  }

}

// Large devices (desktops, 992px and above)
@media (min-width: 992px) { 
  

}

// Extra large devices (large desktops, 1200px and above)
@media (min-width: 1200px) { 

 }