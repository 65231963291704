@import '../../index.scss';
@import '../pages.scss';

// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) {

  #apps-div {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
    color: $primaryTextColor;
    font-family: $primaryFont;
    div h1 {
      font-size: 1.5rem;
    }
    #app-action {
      display: flex;
      align-items: center;
      #search-text {
        background-color: $formInputBackgroundColor;
        color: $primaryTextColor;
        font-family: $primaryFont;
        padding: 0.5rem;
        font-size: 1rem;
        margin-right: 1rem;
      }
      #add-app-btn {
        color: $primaryTextColor;
        background-color: $buttonColor;
        padding: .2rem 0;
        width: 10rem;
        border-radius: 0.5rem;
        span {
          font-size: 1rem;
        }
      }
    }
    #apps-titles {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: $tertiaryBackgroundColor;
      margin-top: 1rem;
      padding: 0.5rem;
      #app-name-header {
        width: 35%;
      }
      #secondary-app-headers {
        justify-content: space-around;
        align-items: center;
        width: 65%;
        margin-right: 0.25rem;
        #app-cust-header {
          width: 50%;
          text-align: center;
        }
        #app-useCase-header {
          width: 50%;
          text-align: center;
        }
        #app-type-header {
          display: none;
        }
        #app-desc-header {
          display: none;
        }
        #app-useCase-header {
          display: none;
        }
      }
    }
    #app-info-div {
      display: flex;
      justify-content: space-between;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      #app-name {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        width: 35%;
      }
      #secondary-app-data {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 65%;
        #app-cust {
          width: 50%;
          text-align: center;
        }
        #app-useCase {
          width: 50%;
          text-align: center;
        }
        #app-type {
          display: none;
        }
        #app-desc {
          display: none;
        }
        #app-useCase {
          display: none;
        }
        #app-more {
          .edit-btn {
            background-color: $buttonColor;
            width: 3rem;
            padding: 0.1rem 0.5rem;
            border-radius: 0.5rem;
            span {
              color: $primaryTextColor;
              font-family: $primaryFont;
              font-size: 1rem;
            }
          }
        }
      }
    }
    
    .app-line {
      border: 1px solid #5A6770;
    }
    #show-more-div {
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      #show-more-btn, #show-less-btn  {
        background-color: $buttonColor;
        width: 7rem;
        padding: 0.5rem 0.5rem;
        border-radius: 0.5rem;
        span {
          color: $primaryTextColor;
          font-family: $primaryFont;
          font-size: 1rem;
        }
      }
      #show-more-btn {
        margin-right: 0.5rem;
      }
      #show-less-btn {
        margin-left: 0.5rem;
      }
    }
  } 

  }
  
  // Small devices (landscape phones, 576 - 767px)
  @media (min-width: 576px) { 

    #apps-div {
      div h1 {
        font-size: 3rem;
      }
      #app-action {
        #search-text {
          font-size: 1.25rem;
          width: 21rem;
        }
        #add-app-btn {
          height: 3rem;
          width: 13rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
      #apps-titles {
        padding: 1rem;
        h2 {
          font-size: 1.25rem;
        }
        #app-name-header {
          width: 20%;
        }
        #secondary-app-headers {
          width: 80%;
          margin: 0;
          #app-cust-header {
            width: 25%;
            text-align: center;
          }
          #app-useCase-header {
            display: block;
            width: 25%;
          }
          #app-type-header {
            display: block;
            width: 25%;
            text-align: center;
          }
          #app-more-header {
            width: 25%;
            text-align: center;
          }
        }
      }
      #app-info-div {
        margin-left: 1rem;
        margin-right: 1rem;
        p {
          font-size: 1.25rem;
        }
        #app-name {
          margin-top: 1rem;
          margin-bottom: 0.5rem;
          width: 20%;
        }
        #secondary-app-data {
          display: flex;
          align-items: center;
          width: 80%;
          #app-cust {
            width: 25%;
          }
          #app-useCase {
            display: block;
            width: 25%;
          }
          #app-type {
            width: 25%;
            display: block;
            text-align: center;
          }
          #app-more {
            width: 25%;
            text-align: center;
            .edit-btn {
              width: 5rem;
              span {
                font-size: 1.25rem;
              }
            }
          }
        }
      }
      #show-more-div {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        #show-more-btn, #show-less-btn  {
          width: 9rem;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }

  }
  
  // Medium devices (tablets, 768px - 991px)
  @media (min-width: 768px) { 

    #apps-div {
      #add-app-btn {
        span {
          font-size: 1.5rem;
        }
      }
      #apps-titles {
        h2 {
          font-size: 1.5rem;
        }
      }
      #app-info-div {
        p {
          font-size: 1.5rem;
        }
      }
    }

   }
  
  // Large devices (desktops, 992px and above)
  @media (min-width: 992px) { 

    #apps-div {
      #apps-titles {
        #app-name-header {
          width: 20%;
        }
        #secondary-app-headers {
          width: 80%;
          #app-cust-header {
            width: 20%;
          }
          #app-useCase-header {
            width: 20%;
          }
          #app-type-header {
            width: 20%;
          }
          #app-desc-header {
            display: block;
            width: 20%;
            text-align: center;
          }
          #app-more-header {
            width: 20%;
          }
        }
      }
      #app-info-div {
        #app-name {
          width: 20%;
        }
        #secondary-app-data {
          width: 80%;
          #app-cust {
            width: 20%;
          }
          #app-useCase {
            width: 20%;
          }
          #app-type {
            width: 20%;
          }
          #app-desc {
            width: 20%;
            display: block;
            text-align: center;
          }
          #app-more {
            width: 20%;
          }
        }
      }
    }

   }
  
  // Extra large devices (large desktops, 1200px and above)
  @media (min-width: 1200px) { 
  
   }